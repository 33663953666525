export const state = () => ({
  cartData: {
    products: [],
    services: [],
  },
  cartProductsSummary: {},
  cartServicesSummary: {},
  isLoading: false,
  inProgressRequestItemIds: [],
  addingCartLoading: false,
  mergedCart: false,
  isStickyTotal: false,
  isCartSynced: false,
  allCartItemsCount: 0,

  isPromocodePending: false,
  promocodeError: null,
});

export const getters = {
  getCartAllItemsCount: (state) => {
    const cartProductsData = state.cartData?.products;
    const cartServicesData = state.cartData?.services;
    const reducer = (accumulator, current) => accumulator + current.count;

    const productsCount = cartProductsData?.length ? cartProductsData.reduce(reducer, 0) : 0;
    const servicesCount = cartServicesData?.length ? cartServicesData.reduce(reducer, 0) : 0;
    const totalProducts = state.isCartSynced ? productsCount + servicesCount : state.allCartItemsCount;

    return {
      products: productsCount,
      services: servicesCount,
      all: totalProducts,
    };
  },
  productInCart: (state) => (id) => {
    return state.cartData.products.some((item) => Number(item.id) === Number(id));
  },
  serviceInCart: (state) => (id) => {
    return state.cartData.services.some((item) => Number(item.id) === Number(id));
  },
  getProductCount: (state, getters) => (id) => {
    const product = state.cartData.products.find((item) => Number(item.id) === Number(id));
    return getters.productInCart(id) ? product?.count : 0;
  },
  getServiceCount: (state, getters) => (id) => {
    const service = state.cartData.services.find((item) => Number(item.id) === Number(id));
    return getters.serviceInCart(id) ? service?.count : 0;
  },
  getProductList: (state) => state.cartData.products,
  getServiceList: (state) => state.cartData.services,
  getDiscounts: (state) => ({
    products: state.cartProductsSummary?.discounts || 0,
    services: state.cartServicesSummary?.discounts || 0,
  }),
  getTotalDiscount: (state) => ({
    products: state.cartProductsSummary?.discounts?.reduce((totalDiscount, discount) => {
      const productDiscount = discount.value?.value;
      return totalDiscount + productDiscount;
    }, 0),
    services: state.cartServicesSummary.discounts?.reduce((totalDiscount, discount) => {
      const productDiscount = discount.value?.value;
      return totalDiscount + productDiscount;
    }, 0),
  }),
  getPrice: (state) => ({
    products: state.cartProductsSummary?.sum?.value ?? 0,
    services: state.cartServicesSummary?.sum?.value ?? 0,
  }),
  getTotalBonuses: (state) => ({
    products: state.cartProductsSummary?.bonusGet ?? 0,
    services: state.cartServicesSummary?.bonusGet ?? 0,
  }),
  getVat: (state) => ({
    products: state.cartProductsSummary?.vatValue ?? 0,
    services: state.cartServicesSummary?.vatValue ?? 0,
  }),
  getTotalPrice: (state) => ({
    products: state.cartProductsSummary?.total?.value ?? 0,
    services: state.cartServicesSummary?.total?.value ?? 0,
  }),
  isCompletedAllRequests: (state) => !state.inProgressRequestItemIds.length,
  getProductCountWithBundle: (state) => (id) => {
    const product = state.cartData.products.find((item) => Number(item.id) === Number(id));
    const bundle = state.cartData.products.find((productItem) => {
      return productItem.items?.some((item) => Number(item.id) === Number(id));
    });

    const productCount = product ? product.count : 0;
    const bundleCount = bundle ? bundle.count : 0;

    return productCount + bundleCount;
  },
  cartPromocode: (state) => state.cartData?.input?.promoCode || null,
};

export const mutations = {
  SET_CART_DATA(
    state,
    payload = { products: [], services: [], input: {} },
  ) {
    state.cartData = payload;
  },
  ADD_CART_PRODUCT_ITEM(state, payload) {
    state.cartData.products.push(payload);
  },
  ADD_CART_SERVICE_ITEM(state, payload) {
    state.cartData.services.push(payload);
  },
  SET_CART_SYNCED(state, payload) {
    state.isCartSynced = payload
  },
  CHANGE_CART_SERVICE_ITEM(state, payload) {
    const currentElem = state.cartData.services.find((item) => Number(item.id) === Number(payload.id));

    if (payload.oldPrice) {
      currentElem.oldPrice = payload.oldPrice;
    }

    if (payload.price) {
      currentElem.price = payload.price;
    }

    if (payload.bonus) {
      currentElem.bonus = payload.bonus ?? currentElem.bonus;
    }

    if (payload.count) {
      currentElem.count = payload.count ?? currentElem.count;
    }

    if (payload.stock) {
      currentElem.stock.qty = payload.stock.qty ?? 0;
    }
  },
  CHANGE_CART_PRODUCT_ITEM(state, payload) {
    const currentElem = state.cartData.products.find((item) => Number(item.id) === Number(payload.id));

    if (payload.oldPrice) {
      currentElem.oldPrice = payload.oldPrice;
    }

    if (payload.price) {
      currentElem.price = payload.price;
    }

    if (payload.bonus) {
      currentElem.bonus = payload.bonus ?? currentElem.bonus;
    }

    if (payload.count) {
      currentElem.count = payload.count ?? currentElem.count;
    }

    if (payload.type === 'bundle_product') {
      if (payload.items?.length > 0) {
        currentElem.items.forEach((item) => {
          const payloadBundleProduct = payload.items.find((payloadItem) => payloadItem.id === item.id);
          item.stock.qty = payloadBundleProduct.stock.qty ?? 0;
        });
      } else {
        currentElem.items.forEach((item) => {
          item.stock.qty = 0;
        });
      }
    } else if (payload.stock) {
      currentElem.stock.qty = payload.stock.qty ?? 0;
    }
  },
  REMOVE_CART_PRODUCT_ITEM(state, id) {
    const removedIdx = state.cartData.products.findIndex((item) => Number(item.id) === Number(id));
    state.cartData.products.splice(removedIdx, 1);
  },
  REMOVE_CART_SERVICE_ITEM(state, id) {
    const removedIdx = state.cartData.services.findIndex((item) => Number(item.id) === Number(id));
    state.cartData.services.splice(removedIdx, 1);
  },
  REMOVE_CART_SERVICE(state, id) {
    const removedIdx = state.cartDataService.findIndex((item) => Number(item.id) === Number(id));
    state.cartDataService.splice(removedIdx, 1);
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ADD_IN_PROGRESS_ITEM_ID(state, payload) {
    state.inProgressRequestItemIds.push(`${payload.id}_${payload.payload}`);
  },
  REMOVE_IN_PROGRESS_ITEM_ID(state, payload) {
    state.inProgressRequestItemIds = state.inProgressRequestItemIds.filter(
      (item) => item !== `${payload.id}_${payload.payload}`,
    );
  },
  ADDING_CART_LOADING(state, payload) {
    state.addingCartLoading = payload;
  },
  SET_MERGED_CART(state, payload) {
    state.mergedCart = payload;
  },
  SET_CART_PRODUCT_SUMMARY(state, payload) {
    state.cartProductsSummary = payload;
  },
  SET_CART_SERVICE_SUMMARY(state, payload) {
    state.cartServicesSummary = payload;
  },
  SET_IS_STICKY_TOTAL(state, payload) {
    state.isStickyTotal = payload;
  },
  SET_CART_ITEMS_COUNT(state, payload) {
    state.allCartItemsCount = payload;
  },
};

export const actions = {
  async getCartItems({ commit }) {
    try {
      commit('SET_IS_LOADING', true);
      const data = await this.$api.cart.getCartItems();

      const { product, service } = prepareCartData(data)

      commit('SET_IS_LOADING', false);
      commit('SET_CART_SYNCED', true);
      commit('SET_CART_DATA', { products: product.items, services: service.items , input: product.input });
      commit('SET_CART_PRODUCT_SUMMARY', product.summary);
      commit('SET_CART_SERVICE_SUMMARY', service.summary);
    } catch (error) {
      console.error(error);
      commit('SET_CART_DATA', {
        products: [],
        services: [],
      });
      commit('SET_CART_PRODUCT_SUMMARY', {});
      commit('SET_CART_SERVICE_SUMMARY', {});
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },

  async addToCartProduct({ state, commit, dispatch, rootState }, product) {
    try {
      if (typeof product.price.value === 'object' && product.price.value.from) {
        product.price.value = product.price.value.from;
      }

      commit('ADDING_CART_LOADING', true);
      commit('ADD_CART_PRODUCT_ITEM', { ...product, count: 1 });

      if (product.type === 'bundle_product') {
        await this.$api.cart.addToCartBundle({
          bundleId: product.id,
          count: 1,
          referrerCode: rootState.auth.referrerCode || '',
        });
      } else {
        await this.$api.cart.addToCartItem({
          offerId: product.id,
          storeId: product.stock.storeId,
          count: 1,
          referrerCode: rootState.auth.referrerCode || '',
        });
      }

      if (!state.isCartSynced) {
        await dispatch('getCartItems')
      }

    } catch (error) {
      console.error(error);
    } finally {
      commit('ADDING_CART_LOADING', false);
    }
  },

  async changeCartProductItemCount({ commit, dispatch }, { product, count }) {
    try {
      commit('ADD_IN_PROGRESS_ITEM_ID', {
        id: product.id,
        payload: `change_count_to_${count}`,
      });

      if (count) {
        commit('ADDING_CART_LOADING', true);
        let response;
        if (product.type === 'bundle_product') {
          response = await this.$api.cart.addToCartBundle({
            bundleId: product.id,
            count,
          });
        } else {
          response = await this.$api.cart.addToCartItem({
            offerId: product.id,
            storeId: product.stock.storeId,
            count,
          });
        }

        const cartProductData = response.product?.items?.find((item) => product.id === item.p?.id);
        if (cartProductData) {
          commit('CHANGE_CART_PRODUCT_ITEM', {
            ...cartProductData.p,
            count: cartProductData.count,
            type: product.type,
          });
        } else {
          commit('CHANGE_CART_PRODUCT_ITEM', {
            id: product.id,
            stock: { qty: 0 },
            type: product.type,
          });
        }

        commit('SET_CART_PRODUCT_SUMMARY', response.product?.summary);
        commit('ADDING_CART_LOADING', false);
      } else {
        dispatch('removeCartProductItem', product);
      }
    } catch (error) {
      console.error(error);
      commit('ADDING_CART_LOADING', false);

      commit('CHANGE_CART_PRODUCT_ITEM', {
        id: product.id,
        stock: { qty: 0 },
        type: product.type,
      });
    } finally {
      commit('REMOVE_IN_PROGRESS_ITEM_ID', {
        id: product.id,
        payload: `change_count_to_${count}`,
      });
    }
  },

  async changeCartServiceItemCount({ commit, dispatch }, { service, count }) {
    try {
      commit('ADD_IN_PROGRESS_ITEM_ID', {
        id: service.id,
        payload: `change_count_to_${count}`,
      });

      if (count) {
        commit('ADDING_CART_LOADING', true);
        const response = await this.$api.cart.addToCartService({
          offerId: service.id,
          count,
        });

        const cartServiceData = response.masterclass?.items?.find((item) => service.id === item.p?.id);
        if (cartServiceData) {
          commit('CHANGE_CART_SERVICE_ITEM', {
            ...cartServiceData.p,
            count: cartServiceData.count,
            type: service.type,
          });
        } else {
          commit('CHANGE_CART_SERVICE_ITEM', {
            id: service.id,
            stock: { qty: 0 },
            type: service.type,
          });
        }

        commit('SET_CART_SERVICE_SUMMARY', response.masterclass?.summary);
        commit('ADDING_CART_LOADING', false);
      } else {
        dispatch('removeCartServiceItem', service);
      }
    } catch (error) {
      console.error(error);
      commit('ADDING_CART_LOADING', false);

      commit('CHANGE_CART_SERVICE_ITEM', {
        id: service.id,
        stock: { qty: 0 },
        type: service.type,
      });
    } finally {
      commit('REMOVE_IN_PROGRESS_ITEM_ID', {
        id: service.id,
        payload: `change_count_to_${count}`,
      });
    }
  },

  async removeCartProductItem({ commit }, product) {
    try {
      commit('ADDING_CART_LOADING', true);
      commit('ADD_IN_PROGRESS_ITEM_ID', { id: product.id, payload: 'remove_item' });

      let response;
      if (product.type === 'bundle_product') {
        response = await this.$api.cart.removeCartBundle({
          data: {
            bundleId: product.id,
          },
        });
      } else {
        response = await this.$api.cart.removeCartItem({
          data: {
            offerId: product.id,
            storeId: product.stock.storeId,
          },
        });
      }

      commit('REMOVE_CART_PRODUCT_ITEM', product.id);
      commit('SET_CART_PRODUCT_SUMMARY', response.product?.summary);
    } catch (error) {
      console.error(error);
    } finally {
      commit('ADDING_CART_LOADING', false);
      commit('REMOVE_IN_PROGRESS_ITEM_ID', { id: product.id, payload: 'remove_item' });
    }
  },

  async removeCartServiceItem({ commit }, service) {
    try {
      commit('ADDING_CART_LOADING', true);
      commit('ADD_IN_PROGRESS_ITEM_ID', { id: service.id, payload: 'remove_item' });

      const response = await this.$api.cart.removeCartService({
        data: {
          offerId: service.id,
        },
      });

      commit('REMOVE_CART_SERVICE_ITEM', service.id);
      commit('SET_CART_SERVICE_SUMMARY', response.masterclass?.summary);
    } catch (error) {
      console.error(error);
    } finally {
      commit('ADDING_CART_LOADING', false);
      commit('REMOVE_IN_PROGRESS_ITEM_ID', { id: service.id, payload: 'remove_item' });
    }
  },

  setMergedCart({ commit }, payload) {
    commit('SET_MERGED_CART', payload);
  },

  clearCart({ commit }) {
    commit('SET_CART_DATA', { products: [], services: [], input: {} });
  },

  getCartItemsCount({ commit, rootState }) {
    const count = rootState.auth.startData ? rootState.auth.startData.count_items : 0;
    commit('SET_CART_ITEMS_COUNT', count);
  },

  updateCartData({ commit, state }, data) {
    const { product, service } = prepareCartData(data)

    commit('SET_CART_DATA', { products: product.items, services: service.items, input: product.input });
    commit('SET_CART_PRODUCT_SUMMARY', product.summary);
    commit('SET_CART_SERVICE_SUMMARY', service.summary);
  },
};

function prepareCartData({ product, masterclass }) {
  let formattedServices, formattedProducts, productSummary, serviceSummary;

  if (product) {
    formattedProducts = product.items
      .map((item) => ({
        ...item.p,
        type: item.type,
        count: item.count,
      }))
      .filter((item) => (item.code && item.categoryCodes) || item.type === 'bundle_product');

    productSummary = product.summary;
  } else {
    formattedProducts = [];
    productSummary = {};
  }

  if (masterclass) {
    formattedServices = masterclass.items.map((item) => ({
      ...item.p,
      type: item.type,
      count: item.count,
    }));

    serviceSummary = masterclass.summary;
  } else {
    formattedServices = [];
    serviceSummary = {};
  }

  return {
    product: {
      items: formattedProducts,
      summary: productSummary,
      input: product?.input || {},
    },
    service: {
      items: formattedServices,
      summary: serviceSummary,
    },
  }
}
