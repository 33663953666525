export const actions = {
  emitImpressions(_, products) {
    if (Array.isArray(products) && products.length) {
      pushToDataLayer({
        event: 'impressions',
        ecommerce: {
          currencyCode: 'RUB',
          impressions: products.map((p) => productToMetricaDto(p)),
        },
      });
    }
  },
  emitProductClick(_, product) {
    if (product) {
      pushToDataLayer({
        event: 'click',
        ecommerce: {
          currencyCode: 'RUB',
          click: {
            products: [
              productToMetricaDto(product),
            ],
          },
        },
      });
    }
  },
  emitProductDetail(_, product) {
    if (product) {
      pushToDataLayer({
        event: 'detail',
        ecommerce: {
          currencyCode: 'RUB',
          detail: {
            products: [
              productToMetricaDto(product),
            ],
          },
        },
      });
    }
  },
  emitProductAddToCart(_, {product, quantity}) {
    if (product) {
      pushToDataLayer({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'RUB',
          add: {
            products: [
              productToMetricaDto(product, quantity),
            ],
          },
        },
      });
    }
  },
  emitProductRemoveFromCart(_, {product, quantity}) {
    if (product) {
      pushToDataLayer({
        event: 'remove',
        ecommerce: {
          currencyCode: 'RUB',
          remove: {
            products: [
              productToMetricaDto(product, quantity),
            ],
          },
        },
      });
    }
  },
  emitPurchase(_, {orderId, products, deliveryPrice, totalPrice}) {
    pushToDataLayer({
      event: 'purchase',
      ecommerce: {
        currencyCode: 'RUB',
        purchase: {
          actionField: {
            id: orderId,
            shipping: deliveryPrice,
            revenue: totalPrice,
          },
          products: products.map(
            (product) => productToMetricaDto(product, product.count),
          ),
        },
      },
    });
  },
  emitRegFormSend() {
    pushToDataLayer({event: 'reg_form_send'});
  },
  emitAuthFormSend() {
    pushToDataLayer({event: 'auth_form_send'});
  },
}

const pushToDataLayer = (event) => {
  logDebugEvent('push to data layer', {event});

  const sender = () => {
    if (window.dataLayer && window.dataLayer.push) {
      window.dataLayer.push(event);
      return true;
    }
    return false;
  };

  if (!sender()) {
    let maxIterations = 100;
    const interval = setInterval(() => {
      if (sender() || --maxIterations <= 0) {
        clearInterval(interval);
      }
    }, 500);
  }
}

const isDebugMode = () => window.localStorage.getItem('ym-debug-mode') === 'true';
const logDebugEvent = (stage, payload) => {
  if (isDebugMode()) {
    console.log({stage, payload});
  }
}

const productToMetricaDto = (product, quantity = null) => {
  const metricaProduct = {
    id: product.id,
    name: product.name || product.title,
    price: product.priceBeforeVat || product?.price?.value?.from || product?.price?.value || 0, 
    brand: product.brand?.name || '',
    category: (product.categoryChain || product.categoryCodes).map((category) => category.name).join('/'),
    ...(isFinite(Number(quantity)) ? {quantity} : {}),
  };
  logDebugEvent('generate product', {product, quantity, metricaProduct});
  return metricaProduct;
}