import env from '@/.env.json';
import { suggestionTypes } from '@/plugins/enums/suggestions';
import { cookieNames } from '@/plugins/enums/cookie';
import { MAX_AGE_COOKIE_CITY } from '@/plugins/config';

export const state = () => ({
  selectedCity: null,
  cityFias: null,
  visibleLocationModal: false,
  isConfirmedCity: false,
  selectedCountry: null,
  countries: [
    { value: 'RU', label: 'Россия' },
    { value: 'BY', label: 'Беларусь' },
    { value: 'KZ', label: 'Казахстан' },
    { value: 'AZ', label: 'Азербайджан' },
    { value: 'AM', label: 'Армения' },
    { value: 'KG', label: 'Кыргызстан' },
    { value: 'MD', label: 'Молдова' },
    { value: 'TJ', label: 'Таджикистан' },
    { value: 'TR', label: 'Турция' },
    { value: 'TM', label: 'Туркменистан' },
    { value: 'UZ', label: 'Узбекистан' },
  ],
});

export const getters = {
  getSelectedCity: (state) => (state.selectedCity && state.selectedCity.name) || 'Выберите город',
  getSelectedCityType: (state) => state.selectedCity?.type ? `${state.selectedCity?.type}.` : '',
  getSelectedCityCoords: (state) => [Number(state.selectedCity?.geo_lat), Number(state.selectedCity?.geo_lon)],
  getCountriesList: (state) => {
    let active = [ env?.COUNTRY || 'RU' ]

    if (env.ACTIVE_COUNTRIES) {
      active = env.ACTIVE_COUNTRIES.split(',')
    }

    const countries = []

    active.forEach(item => {
      const country = state.countries.find(country => country.value === item)

      if (country) {
        countries.push(country)
      }
    })

    return countries
  },
  getSelectedCountry: (state) => {
    if (!state.selectedCountry) {
      const countryCode = env?.COUNTRY || 'RU'
      return state.countries.find(item => item.value === countryCode)
    }

    return state.selectedCountry
  },
};

export const mutations = {
  SET_SELECTED_COUNTRY: (state, payload) => {
    state.selectedCountry = payload;
  },
  SET_SELECTED_CITY: (state, payload) => {
    state.selectedCity = payload;
  },
  SET_IS_CONFIRMED_CITY(state, payload) {
    state.isConfirmedCity = payload;
  },
  SET_CITY_FIAS(state, payload) {
    state.cityFias = payload;
  },
  SET_VISIBLE_LOCATION_MODAL(state, payload) {
    state.visibleLocationModal = payload;
  },
};

export const actions = {
  findAddress({ commit, state }, payload) {
    const data = {
      query: payload.query,
      count: payload.count,
      locations: payload.locations,
    }
    if (payload.type) {
      const fromBound = { value: payload.type };
      let toBound;

      switch (payload.type) {
        case suggestionTypes.REGION:
          toBound = { value: suggestionTypes.AREA };
          break;
        case suggestionTypes.CITY:
          toBound = { value: suggestionTypes.SETTLEMENT };
          break;
        case suggestionTypes.HOUSE:
          break;
        default:
          toBound = { value: payload.type };
      }

      if (payload.bound) {
        toBound = { value: payload.bound }
      }

      data.from_bound = fromBound;
      data.to_bound = toBound;
    }
    if (payload.locationsBoost) {
      data.locations_boost = [{
        kladr_id: state.selectedCity?.kladr_id,
      }];
    }

    return this.$api.geolocation.findAddressDadata(data);
  },
  setIsConfirmedCity({ commit }, payload) {
    commit('SET_IS_CONFIRMED_CITY', payload);
  },
  async geolocateAddress({ commit }, payload) {
    try {
      const { suggestions } = await this.$api.geolocation.geolocateAddressDadata(payload);
      return suggestions;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async setSelectedCity({ state, commit }, payload) {
    try {
      const data = {
        name: payload.city.name,
        fias_id: payload.city.fias_id,
        region_fias_id: payload.city.region_fias_id,
      }
      if (payload.setCookie) {
        this.$cookies.set(cookieNames.GEOLOCATION, JSON.stringify(payload.city), {
          maxAge: MAX_AGE_COOKIE_CITY,
          path: '/',
        });
      }

      const { country_iso_code: code } = payload.city
      const countryCode = code || env?.COUNTRY
      const currentCountry = state.countries.find(item => item.value === countryCode)

      // Почему-то зависает при принудительной загрузке в корзине (Nuxt SSR)
      await this.$api.geolocation.setSelectedCity(data);

      commit('SET_SELECTED_CITY', payload.city);
      commit('SET_SELECTED_COUNTRY', currentCountry)
    } catch (error) {
      console.error(error);
    }
  },
  async getSelectedCityByIp({ getters, rootState }) {
    const ip = rootState.auth.startData ? rootState.auth.startData.ip : await this.$api.geolocation.getIpUrl();

    const defaultCity = {
      name: 'Москва',
      type: 'г',
      fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      geo_lat: '55.75396',
      geo_lon: '37.620393',
      country_iso_code: 'RU',
    };
    try {
      if (ip) {
        const { location } = await this.$api.geolocation.getSelectedCityByIpDadata(ip);
        if (location) {
          const locationData = location.data;
          return {
            name: locationData.settlement || locationData.city,
            type: locationData.settlement_type || locationData.city_type,
            fias_id: locationData.settlement_fias_id || locationData.city_fias_id,
            geo_lat: locationData.geo_lat,
            geo_lon: locationData.geo_lon,
            kladr_id: locationData.kladr_id,
            country_iso_code: locationData.country_iso_code || getters.getSelectedCountry.value,
          };
        }
      }
      return defaultCity;
    } catch (error) {
      console.error(error);
      return defaultCity;
    }
  },
  async setSelectedCityByIp({ dispatch }) {
    try {
      let city = this.$cookies.get(cookieNames.GEOLOCATION);
      if (!city) city = await dispatch('getSelectedCityByIp');
      await dispatch('setSelectedCity', {
        city,
        setCookie: false,
      });
    } catch (error) {
      console.error(error);
    }
  },

  async selectCity({ commit, getters, dispatch, rootState, rootGetters }, { suggestion, locations }) {
    try {
      const { suggestions } = await dispatch('findAddress', {
        type: suggestionTypes.CITY,
        query: suggestion.value,
        count: 1,
        locations,
      })
      const selectedCitySuggestion = suggestions[0];
      if (selectedCitySuggestion) {
        dispatch('setSelectedCitySuggestion', selectedCitySuggestion)
      }
    } catch (error) {
      console.error(error);
    }
  },

  async setSelectedCitySuggestion({ commit, getters, dispatch, rootState, rootGetters }, suggestion){
    try {
      const { data } = suggestion

      await dispatch('setSelectedCity', {
        city: {
          name: data.settlement || data.city,
          type: data.settlement_type || data.city_type,
          fias_id: data.settlement_fias_id || data.city_fias_id,
          geo_lat: data.geo_lat,
          geo_lon: data.geo_lon,
          region_fias_id: data.region_fias_id,
          kladr_id: data.kladr_id ?? null,
          country_iso_code: data.country_iso_code || getters.getSelectedCountry.value,
        },
        setCookie: true,
      });

      commit('SET_CITY_FIAS', {
        city: data.settlement || data.city,
        city_guid: data.settlement_fias_id || data.city_fias_id,
        country_code: getters.getSelectedCountry.value,
        post_index: data.postal_code,
        region: data.region,
        region_guid: data.region_fias_id,
      })

      commit('SET_IS_CONFIRMED_CITY', false);
    } catch (e) {
      console.log(e)
    }
  },
};
