import env from '@/.env.json';

export function getOrganization(logo) {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'url': env.BASE_URL,
    'contactPoint': [{
      '@type': 'ContactPoint',
      'telephone': '+7 (495) 128 83 22',
      'contactType': 'Контактный номер',
    }],
    'logo': `${logo}`,
  }
}
