import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4e89fb61 = () => interopDefault(import('@/pages/main.vue' /* webpackChunkName: "" */))
const _40dd6f04 = () => interopDefault(import('@/pages/socialLogin.vue' /* webpackChunkName: "" */))
const _b72d7860 = () => interopDefault(import('@/pages/auth.vue' /* webpackChunkName: "" */))
const _077ae39e = () => interopDefault(import('@/pages/profile.vue' /* webpackChunkName: "" */))
const _2b7debf4 = () => interopDefault(import('@/pages/profile/index.vue' /* webpackChunkName: "" */))
const _4c05ad8a = () => interopDefault(import('@/pages/profile/certificates.vue' /* webpackChunkName: "" */))
const _1445f79f = () => interopDefault(import('@/pages/profile/company.vue' /* webpackChunkName: "" */))
const _6f1303a1 = () => interopDefault(import('@/pages/profile/contacts.vue' /* webpackChunkName: "" */))
const _487882b3 = () => interopDefault(import('@/pages/profile/orders.vue' /* webpackChunkName: "" */))
const _0956f6f6 = () => interopDefault(import('@/pages/profile/orders/index.vue' /* webpackChunkName: "" */))
const _11023ede = () => interopDefault(import('@/pages/profile/orders/_id.vue' /* webpackChunkName: "" */))
const _7d4fa6e1 = () => interopDefault(import('@/pages/profile/orders/_id/index.vue' /* webpackChunkName: "" */))
const _5270e231 = () => interopDefault(import('@/pages/profile/orders/_id/return.vue' /* webpackChunkName: "" */))
const _150c00c3 = () => interopDefault(import('@/pages/profile/orders/_id/update-return.vue' /* webpackChunkName: "" */))
const _49ef5d36 = () => interopDefault(import('@/pages/profile/returns.vue' /* webpackChunkName: "" */))
const _40697828 = () => interopDefault(import('@/pages/profile/returns/index.vue' /* webpackChunkName: "" */))
const _24c76490 = () => interopDefault(import('@/pages/profile/returns/_id.vue' /* webpackChunkName: "" */))
const _100b81da = () => interopDefault(import('@/pages/profile/returns/_id/index.vue' /* webpackChunkName: "" */))
const _3462607e = () => interopDefault(import('@/pages/profile/returns/_id/confirm.vue' /* webpackChunkName: "" */))
const _4e09d0e2 = () => interopDefault(import('@/pages/profile/returns/_id/create-return.vue' /* webpackChunkName: "" */))
const _d8c4091c = () => interopDefault(import('@/pages/profile/bonuses/index.vue' /* webpackChunkName: "" */))
const _9ba9c320 = () => interopDefault(import('@/pages/profile/blog.vue' /* webpackChunkName: "" */))
const _596310f3 = () => interopDefault(import('@/pages/profile/blog/index.vue' /* webpackChunkName: "" */))
const _0a994faa = () => interopDefault(import('@/pages/profile/blog/create.vue' /* webpackChunkName: "" */))
const _c7469bd0 = () => interopDefault(import('@/pages/profile/subscriptions.vue' /* webpackChunkName: "" */))
const _7b38ddda = () => interopDefault(import('@/pages/profile/documents.vue' /* webpackChunkName: "" */))
const _b952b572 = () => interopDefault(import('@/pages/profile/addresses/index.vue' /* webpackChunkName: "" */))
const _7e603a9d = () => interopDefault(import('@/pages/profile/preferences/index.vue' /* webpackChunkName: "" */))
const _568b59f0 = () => interopDefault(import('@/pages/profile/promocodes/index.vue' /* webpackChunkName: "" */))
const _8f09428e = () => interopDefault(import('@/pages/profile/commission.vue' /* webpackChunkName: "" */))
const _0ea05855 = () => interopDefault(import('@/pages/profile/bill.vue' /* webpackChunkName: "" */))
const _f3bacf0a = () => interopDefault(import('@/pages/profile/referal/_id/index.vue' /* webpackChunkName: "" */))
const _7b7274e0 = () => interopDefault(import('@/pages/profile/referal/index.vue' /* webpackChunkName: "" */))
const _59c15b9a = () => interopDefault(import('@/pages/profile/promo-products/index.vue' /* webpackChunkName: "" */))
const _220c3918 = () => interopDefault(import('@/pages/profile/promo/index.vue' /* webpackChunkName: "" */))
const _d9944c28 = () => interopDefault(import('@/pages/profile/promo/list.vue' /* webpackChunkName: "" */))
const _5380ffcc = () => interopDefault(import('@/pages/profile/messages.vue' /* webpackChunkName: "" */))
const _7bce0b1d = () => interopDefault(import('@/pages/profile/messages/index.vue' /* webpackChunkName: "" */))
const _3eae30c5 = () => interopDefault(import('@/pages/profile/messages/_id.vue' /* webpackChunkName: "" */))
const _6e2cf272 = () => interopDefault(import('@/pages/referrer.vue' /* webpackChunkName: "" */))
const _05ed4fcd = () => interopDefault(import('@/pages/blog/index.vue' /* webpackChunkName: "" */))
const _77929ddc = () => interopDefault(import('@/pages/blog/all.vue' /* webpackChunkName: "" */))
const _0ccf5074 = () => interopDefault(import('@/pages/blog/authors/index.vue' /* webpackChunkName: "" */))
const _69b88ac4 = () => interopDefault(import('@/pages/blog/authors/_author.vue' /* webpackChunkName: "" */))
const _93bbb2e8 = () => interopDefault(import('@/pages/blog/_article.vue' /* webpackChunkName: "" */))
const _7c897664 = () => interopDefault(import('@/pages/catalog/index.vue' /* webpackChunkName: "" */))
const _1c397fe4 = () => interopDefault(import('@/pages/catalog/_product.vue' /* webpackChunkName: "" */))
const _6643b060 = () => interopDefault(import('@/pages/search.vue' /* webpackChunkName: "" */))
const _4f6e6548 = () => interopDefault(import('@/pages/cart.vue' /* webpackChunkName: "" */))
const _3261ed6c = () => interopDefault(import('@/pages/promos.vue' /* webpackChunkName: "" */))
const _a12c9a22 = () => interopDefault(import('@/pages/promos/index.vue' /* webpackChunkName: "" */))
const _843201f4 = () => interopDefault(import('@/pages/promos/_promo/index.vue' /* webpackChunkName: "" */))
const _c6292724 = () => interopDefault(import('@/pages/checkout.vue' /* webpackChunkName: "" */))
const _a19a4550 = () => interopDefault(import('@/pages/checkout/products/index.vue' /* webpackChunkName: "" */))
const _5ec5a85c = () => interopDefault(import('@/pages/checkout/services/index.vue' /* webpackChunkName: "" */))
const _5c3de386 = () => interopDefault(import('@/pages/services.vue' /* webpackChunkName: "" */))
const _65572d89 = () => interopDefault(import('@/pages/services/index.vue' /* webpackChunkName: "" */))
const _4347fb12 = () => interopDefault(import('@/pages/services/_service' /* webpackChunkName: "" */))
const _670eec11 = () => interopDefault(import('@/pages/experts.vue' /* webpackChunkName: "" */))
const _60214522 = () => interopDefault(import('@/pages/thank-you/_id.vue' /* webpackChunkName: "" */))
const _b7148c0e = () => interopDefault(import('@/pages/sets.vue' /* webpackChunkName: "" */))
const _bd79bc88 = () => interopDefault(import('@/pages/sets/index.vue' /* webpackChunkName: "" */))
const _11d2e329 = () => interopDefault(import('@/pages/sets/_set.vue' /* webpackChunkName: "" */))
const _e693c21a = () => interopDefault(import('@/pages/lookbooks.vue' /* webpackChunkName: "" */))
const _1d033994 = () => interopDefault(import('@/pages/lookbooks/index.vue' /* webpackChunkName: "" */))
const _1ee46d4b = () => interopDefault(import('@/pages/lookbooks/_lookbook.vue' /* webpackChunkName: "" */))
const _186a4330 = () => interopDefault(import('@/pages/giftcard.vue' /* webpackChunkName: "" */))
const _40958bdf = () => interopDefault(import('@/pages/favorites.vue' /* webpackChunkName: "" */))
const _4a2e35f4 = () => interopDefault(import('@/pages/brands.vue' /* webpackChunkName: "" */))
const _cda11512 = () => interopDefault(import('@/pages/brands/index.vue' /* webpackChunkName: "" */))
const _49aa289a = () => interopDefault(import('@/pages/brands/_brand.vue' /* webpackChunkName: "" */))
const _6273ab92 = () => interopDefault(import('@/pages/_slug.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _4e89fb61,
    name: "main"
  }, {
    path: "/social-login/:driver",
    component: _40dd6f04,
    name: "social-login"
  }, {
    path: "/auth",
    component: _b72d7860,
    name: "auth"
  }, {
    path: "/profile",
    component: _077ae39e,
    meta: {"availableAuthorized":true},
    children: [{
      path: "",
      component: _2b7debf4,
      name: "profile"
    }, {
      path: "certificates",
      component: _4c05ad8a,
      name: "profile.certificates"
    }, {
      path: "company",
      component: _1445f79f,
      name: "profile.company"
    }, {
      path: "contacts",
      component: _6f1303a1,
      name: "profile.contacts"
    }, {
      path: "orders",
      component: _487882b3,
      children: [{
        path: "",
        component: _0956f6f6,
        name: "profile.orders"
      }, {
        path: ":id",
        component: _11023ede,
        children: [{
          path: "",
          component: _7d4fa6e1,
          name: "profile.order"
        }, {
          path: "return",
          component: _5270e231,
          name: "profile.order.return"
        }, {
          path: "update-return",
          component: _150c00c3,
          name: "profile.order.update-return"
        }]
      }]
    }, {
      path: "returns",
      component: _49ef5d36,
      children: [{
        path: "",
        component: _40697828,
        name: "profile.returns"
      }, {
        path: ":id",
        component: _24c76490,
        children: [{
          path: "",
          component: _100b81da,
          name: "profile.return"
        }, {
          path: "confirm",
          component: _3462607e,
          name: "profile.return.confirm"
        }, {
          path: "create-return",
          component: _4e09d0e2,
          name: "profile.return.create-return"
        }]
      }]
    }, {
      path: "bonuses",
      component: _d8c4091c,
      children: [{
        path: "",
        component: _d8c4091c,
        name: "profile.bonuses"
      }]
    }, {
      path: "blog",
      component: _9ba9c320,
      children: [{
        path: "",
        component: _596310f3,
        name: "profile.blog"
      }, {
        path: "create",
        component: _0a994faa,
        name: "profile.blog.create"
      }]
    }, {
      path: "subscriptions",
      component: _c7469bd0,
      name: "profile.subscriptions"
    }, {
      path: "documents",
      component: _7b38ddda,
      name: "profile.documents"
    }, {
      path: "addresses",
      component: _b952b572,
      name: "profile.addresses"
    }, {
      path: "preferences",
      component: _7e603a9d,
      name: "profile.preferences",
      children: [{
        path: ":name",
        component: _7e603a9d,
        meta: {"isReferalPartnersOnlyRoute":true},
        name: "profile.pro-preferences"
      }]
    }, {
      path: "promocodes",
      component: _568b59f0,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-promocodes"
    }, {
      path: "commission",
      component: _8f09428e,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-commission"
    }, {
      path: "bill",
      component: _0ea05855,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-bill"
    }, {
      path: "referal/:id",
      component: _f3bacf0a,
      meta: {"samePagePosition":true,"isReferalPartnersOnlyRoute":true},
      name: "profile.referal.id"
    }, {
      path: "referal",
      component: _7b7274e0,
      meta: {"samePagePosition":true,"isReferalPartnersOnlyRoute":true},
      name: "profile.referal"
    }, {
      path: "promo-products",
      component: _59c15b9a,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.promo-products"
    }, {
      path: "promopage",
      component: _220c3918,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-promo"
    }, {
      path: "promopages",
      component: _d9944c28,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-promo-list"
    }, {
      path: "messages",
      component: _5380ffcc,
      children: [{
        path: "",
        component: _7bce0b1d,
        name: "profile.messages"
      }, {
        path: ":id",
        component: _3eae30c5,
        name: "profile.chat"
      }]
    }]
  }, {
    path: "/referrer/:code",
    component: _6e2cf272,
    name: "referrer.promopage"
  }, {
    path: "/blog",
    component: _05ed4fcd,
    meta: {"scrollTop":true},
    name: "blog"
  }, {
    path: "/blog/all",
    component: _77929ddc,
    meta: {"scrollTop":true},
    name: "blog.all"
  }, {
    path: "/blog/all/tag/:tag",
    component: _77929ddc,
    meta: {"scrollTop":true},
    name: "blog.tag"
  }, {
    path: "/blog/authors",
    component: _0ccf5074,
    meta: {"scrollTop":true},
    name: "blog.authors"
  }, {
    path: "/blog/authors/:author",
    component: _69b88ac4,
    meta: {"scrollTop":true},
    name: "blog.authors.author"
  }, {
    path: "/blog/:article",
    component: _93bbb2e8,
    meta: {"scrollTop":true},
    name: "blog.article"
  }, {
    path: "/catalog",
    component: _7c897664,
    meta: {"samePagePosition":true},
    name: "catalog"
  }, {
    path: "/catalog/filters/*",
    component: _7c897664,
    meta: {"samePagePosition":true},
    name: "catalog.filters"
  }, {
    path: "/catalog/:category",
    component: _7c897664,
    meta: {"samePagePosition":true},
    name: "catalog.category"
  }, {
    path: "/catalog/:category/filters/*",
    component: _7c897664,
    meta: {"samePagePosition":true},
    name: "catalog.category.filters"
  }, {
    path: "/catalog/:category/tag/:tag",
    component: _7c897664,
    meta: {"samePagePosition":true},
    name: "catalog.category.tag"
  }, {
    path: "/catalog/:category/tag/:tag/filters/*",
    component: _7c897664,
    meta: {"samePagePosition":true},
    name: "catalog.category.tag.filters"
  }, {
    path: "/catalog/:category/:product",
    component: _1c397fe4,
    name: "catalog.category.product"
  }, {
    path: "/search",
    component: _6643b060,
    children: [{
      path: "",
      component: _6643b060,
      name: "search"
    }, {
      path: "filters/*",
      component: _6643b060,
      name: "search.filters"
    }, {
      path: ":category",
      component: _6643b060,
      children: [{
        path: "",
        component: _6643b060,
        name: "search.category"
      }, {
        path: "filters/*",
        component: _6643b060,
        name: "search.category.filters"
      }]
    }]
  }, {
    path: "/cart",
    component: _4f6e6548,
    name: "cart"
  }, {
    path: "/promo",
    component: _3261ed6c,
    children: [{
      path: "",
      component: _a12c9a22,
      name: "promos"
    }, {
      path: ":entityCode",
      component: _843201f4,
      name: "promos.promo"
    }]
  }, {
    path: "/checkout",
    component: _c6292724,
    meta: {"availableAuthorized":true},
    children: [{
      path: "/checkout/products",
      component: _a19a4550,
      name: "checkout.products"
    }, {
      path: "/checkout/services",
      component: _5ec5a85c,
      name: "checkout.services"
    }]
  }, {
    path: "/services",
    component: _5c3de386,
    children: [{
      path: "",
      component: _65572d89,
      name: "services"
    }, {
      path: ":service",
      component: _4347fb12,
      name: "services.service"
    }]
  }, {
    path: "/experts",
    component: _670eec11
  }, {
    path: "/thank-you/:id",
    component: _60214522
  }, {
    path: "/sets",
    component: _b7148c0e,
    children: [{
      path: "",
      component: _bd79bc88,
      name: "sets"
    }, {
      path: ":entityCode",
      component: _11d2e329,
      name: "sets.set"
    }]
  }, {
    path: "/lookbooks",
    component: _e693c21a,
    children: [{
      path: "",
      component: _1d033994,
      name: "lookbooks"
    }, {
      path: ":lookbook",
      component: _1ee46d4b,
      name: "lookbooks.lookbook"
    }]
  }, {
    path: "/giftcard",
    component: _186a4330,
    name: "giftcard"
  }, {
    path: "/favorites",
    component: _40958bdf,
    meta: {"availableAuthorized":true},
    name: "favorites"
  }, {
    path: "/brands",
    component: _4a2e35f4,
    children: [{
      path: "",
      component: _cda11512,
      name: "brands"
    }, {
      path: ":entityCode",
      component: _49aa289a,
      name: "brands.brand",
      children: [{
        path: "filters/*",
        component: _49aa289a,
        name: "brands.brand.filters"
      }, {
        path: ":category",
        component: _49aa289a,
        name: "brands.brand.category",
        children: [{
          path: "filters/*",
          component: _49aa289a,
          name: "brands.brand.category.filters"
        }]
      }]
    }]
  }, {
    path: "*",
    component: _6273ab92,
    name: "any"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
