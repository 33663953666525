
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import ProfileInitials from '~/components/profile/Initials';

export default {
  name: 'HeaderMainActionsAccountMenu',
  components: {ProfileInitials},
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters({
      getAvatarPlaceholder: 'auth/getAvatarPlaceholder',
      currentCompany: 'auth/currentCompany',
      getUserCompanies: 'auth/getUserCompanies',
      isAccountOrganization: 'auth/isAccountOrganization',
    }),
    isUserReferral() {
      return this.user?.referralPartner
    },
    userName() {
      const firstName = this.user?.firstName || ''
      const lastName = this.user?.lastName || ''

      return `${firstName} ${lastName}`
    },
    profileInitials() {
      return {
        initials: !this.isAccountOrganization ? this.getAvatarPlaceholder : '',
        icon: this.isAccountOrganization ? 'company' : '',
        iconClass: this.isAccountOrganization ? 'profile-initials__icon_inverse' : 'profile-initials__icon_border',
        name: this.isAccountOrganization ? this.currentCompany?.name || '' : this.userName,
      }
    },
    userCompanies() {
      return this.getUserCompanies.filter(item => item.is_organization)
    },
    userAccounts() {
      return this.getUserCompanies.filter(item => !item.is_organization)
    },
  },
  methods: {
    ...mapActions({
      setUserCompany: 'profile/setUserCompany',
    }),
    ...mapActions('auth', ['logout']),
    ...mapMutations('profile', {
      setModalCompany: 'SET_MODAL_COMPANY',
    }),
    async onLogout() {
      this.isPendingLogout = true;
      await this.logout();
      this.isPendingLogout = false;
      if (this.$route.meta.availableAuthorized || this.$route.matched.some((item) => item.meta.availableAuthorized)) {
        this.$router.push('/');
      }
    },
    async setCurrentCompany(item) {
      await this.setUserCompany(item)
    },
    onAddCompanyHandler() {
      this.setModalCompany(true)
    },
  },
}
