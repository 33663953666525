
import CoolLightBox from 'vue-cool-lightbox';
import ProductSliderImages from '~/components/catalog/productSliderImages';
import CustomImage from '~/components/elements/CustomImage';
import VideoIcon from '~/components/elements/VideoIcon';
import {
  generateYoutubePreview,
  generateYoutubeVideoSourcePath,
} from '~/plugins/helpers/file';

export default {
  name: 'QuickLookGallery',
  components: {
    VideoIcon,
    CustomImage,
    ProductSliderImages,
    CoolLightBox,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      imageOpenIndex: null,
    }
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs';
    },
    pngImages() {
      let slides = []

      slides = this.images?.map(image => {
        return {
          url: image.url,
          type: 'image',
        }
      });

      if (this.productVideo) {
        slides.push({
          src: generateYoutubePreview(this.productVideo),
          type: 'youtube',
        })
      }

      return slides
    },
    galleryItems() {
      let slides = []

      slides = this.images?.map(image => {
        return {
          url: image.url.large,
        }
      })

      if (this.productVideo) {
        slides.push({ src: generateYoutubeVideoSourcePath(this.productVideo) })
      }

      return slides
    },
    productVideo() {
      return this.product?.description?.video || ''
    },
  },
  methods: {
    openImage(index) {
      this.imageOpenIndex = index;
    },
  },
}
