
export default {
  name: 'CompanySearchItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onClickHandler() {
      this.$emit('onClick', this.item)
    },
  },
}
