export const cartPageTypes = Object.freeze({
  PRODUCTS: 'products',
  SERVICES: 'services',
})

export const productGroupTypes = Object.freeze({
  CATALOG: 'catalog',
  NEW: 'new',
  PROMO: 'promo',
  SETS: 'sets',
  LOOKBOOKS: 'lookbooks',
  BRANDS: 'brands',
  BESTSELLERS: 'bestsellers',
  SEARCH: 'search',
});

export const productGroupBase = Object.freeze({
  FILTERS: 'filters',
  PRODUCTS: 'products',
});

export const productGroupSortFields = Object.freeze({
  NAME: 'name',
  CREATED_AT: 'created_at',
});

export const reviewOpinion = Object.freeze({
  LIKE: 'like',
  DISLIKE: 'dislike',
});


