
import CustomImage from '~/components/elements/CustomImage';

export default {
  name: 'SearchResultProductsItem',
  components: {CustomImage},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getImage(product) {
      if (product.image) {
        return product.image.url
      } else if (Array.isArray(product.media) && product.media.length > 0) {
        return product.media[0].url
      }

      return '';
    },
    productName(product) {
      return product.name ?? product.title;
    },
  },
}
