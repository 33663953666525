export default axios => ({
  getStartedData() {
    try {
      return axios.$get('/v1/start');
    } catch (error) {
      throw new Error(`API Request Error (getStartedData): ${error}`);
    }
  },
  getLogo() {
    try {
      return axios.$get('/v1/content/logotype');
    } catch (error) {
      throw new Error(`API Request Error (getLogo): ${error}`);
    }
  },
  getMenu() {
    try {
      return axios.$get('/v1/content/menus');
    } catch (error) {
      throw new Error(`API Request Error (getMenu): ${error}`);
    }
  },
  getBanners(typeCode = 'catalog', path = '') {
    try {
      return axios.$get('/v1/content/banners', {
        params: { typeCode, path },
      });
    } catch (error) {
      throw new Error(`API Request Error (getBanners): ${error}`);
    }
  },
  getPageLandingOld(url) {
    try {
      return axios.$get('/v1/content/pages', {
        params: {
          url,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getPages): ${error}`);
    }
  },
  getPage(path = '', params = {}) {
    try {
      return axios.$get('/v1/page', {
        params: {
          path,
          ...params,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getPage): ${error}`);
    }
  },
  getPageMeta(path = '') {
    try {
      return axios.$get('/v1/meta', {
        params: {
          path,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getPageMeta): ${error}`);
    }
  },
  getPageText(path = '') {
    try {
      return axios.$get('/v1/listing', {
        params: {
          path,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getPageText): ${error}`);
    }
  },
  getPageConstructor(path = '') {
    try {
      return axios.$get('/v1/landing-widget', {
        params: {
          path,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getPageConstructor): ${error}`);
    }
  },
});
