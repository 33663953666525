export class GTMEvent {
  constructor(clients = []) {
    this.clients = clients
  }

 get data() {
   const data = {}

   this.clients.forEach(client => {
     Object.keys(client).forEach(key => {
       data[key] = client[key]
     })
   })

   return data
 }
}
