
const validTypes = ['checkbox', 'radio'];

export default {
  name: 'CustomCheck',
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    modelValue: {
      type: [Number, String, Boolean],
      default: undefined,
    },
    checked: {
      type: [Boolean, Number, String],
      default: null,
    },
    id: {
      type: [Number, String],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String],
      default: '',
    },
    type: {
      type: String,
      default: 'checkbox',
      validator(value) {
        // Значение должно соответствовать одной из этих строк
        return validTypes.includes(value) !== -1;
      },
    },
    indeterminate: {
      type: Boolean,
    },
    isSwitch: {
      type: Boolean,
    },
    dataTest: {
      type: String,
      default: null,
    },
    isCancelableRadio: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      m_checked: undefined,
    };
  },
  computed: {
    shouldBeChecked() {
      if (this.modelValue !== undefined) {
        // radio
        if (this.type === 'radio') return this.modelValue === this.value;
        // checkbox
        else if (this.modelValue instanceof Array) return this.modelValue.includes(this.value);
        return typeof this.modelValue === 'string' ? true : !!this.modelValue;
      }
      // this.modelValue === undefined
      if (this.m_checked === undefined) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return (this.m_checked = typeof this.checked === 'string' ? true : !!this.checked);
      } else {
        return this.m_checked && !this.indeterminate;
      }
    },
  },
  watch: {
    checked(value, old) {
      // чтобы чекбокс правильно обновлялся
      this.$nextTick(() => (this.m_checked = value));
    },

    indeterminate(value) {
      const { input } = this.$refs;
      if (input) input.indeterminate = value;
    },
  },
  methods: {
    onCancelCheckedClick(event) {
      if (this.type === 'radio' && this.modelValue && this.isCancelableRadio) {
        this.$emit('onCancelCheckedClick', event);
      }
    },
    updateInput(event) {
      if (this.type === 'radio') {
        this.$emit('change', this.value);
        return;
      }
      const isChecked = event.target.checked;
      this.m_checked = isChecked;
      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue];
        if (isChecked) newValue.push(this.value);
        else newValue.splice(newValue.indexOf(this.value), 1);

        this.$emit('change', newValue);
      } else this.$emit('change', isChecked);
    },
  },
};
