
import {mapGetters, mapMutations, mapState} from 'vuex';
import env from '@/.env.json';
import Support from '~/layouts/components/Header/Support'
import HeaderMobileMenuList from '~/layouts/components/Header/MobileMenu/List';

export default {
  name: 'HeaderMobileMenuFirst',
  components: {HeaderMobileMenuList, Support},
  data() {
    const fastCodes = env?.MOBILE_FAST_CATEGORIES || ''

    return {
      fastCategoryCodes: fastCodes.split(',').map(code => code.trim()).filter(code => code),
    }
  },
  computed: {
    ...mapState('catalog', ['allCategories']),
    ...mapState('auth', ['hasSession']),
    ...mapGetters({
      menuMain: 'getMenuMain',
      menuHelp: 'getMenuHelp',
      getFavoritesCount: 'favorites/getFavoritesCount',
      getSelectedCity: 'geolocation/getSelectedCity',
      formattedAllCategories: 'catalog/formattedAllCategories',
    }),
    fastCategoriesMenu() {
      return this.fastCategoryCodes.map(code => this.formattedAllCategories[code]).filter(category => category)
    },
  },
  methods: {
    ...mapMutations('geolocation', {
      setVisibleLocationModal: 'SET_VISIBLE_LOCATION_MODAL',
    }),
    onLinkClick() {
      this.$emit('linkClick')
    },
    showCategories(event, parent) {
      this.$emit('showCategories', event, parent)
    },


    openLocationModal() {
      this.setVisibleLocationModal(true);
    },
  },
}
