export default {
  methods: {
    appHeight() {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    },
  },
  mounted() {
    this.appHeight();

    window.addEventListener('resize', this.appHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.appHeight)
  },
};
