import qs from 'qs';
import { productGroupSortFields, productGroupTypes } from '@/plugins/enums/product';
import { sortDirections } from '@/plugins/enums/sort';
import { REQUEST_CANCEL_MESSAGE } from '@/plugins/config';

let catalogItemsCancelSource = null;

export default axios => ({
  getProductGroup(type, code, filter = {}) {
    try {
      switch (type) {
        case productGroupTypes.LOOKBOOKS:
          return axios.$get('/v1/catalog/lookbook', {
            params: {
              code,
            },
          });
        case productGroupTypes.SEARCH:
        case productGroupTypes.SETS:
        case productGroupTypes.BRANDS:
        case productGroupTypes.PROMO:
          return axios.get('/v1/catalog/product-group', {
            params: {
              type_code: type,
              code: code ?? undefined,
              filter,
            },
            paramsSerializer(params) {
              return qs.stringify(params, {
                encode: false,
              });
            },
          });
      }
    } catch (error) {
      if (error.response.status === 404) {
        throw error;
      }
      throw new Error(`API Request Error (getProductGroup): ${error}`);
    }
  },
  async getCatalogItems(params = {}) {
    try {
      if (catalogItemsCancelSource) {
        catalogItemsCancelSource.cancel(REQUEST_CANCEL_MESSAGE);
        catalogItemsCancelSource = axios.CancelToken.source();
      } else {
        catalogItemsCancelSource = axios.CancelToken.source();
      }

      return await axios.$get('/v1/catalog/products', {
        cancelToken: catalogItemsCancelSource.token,
        params,
        paramsSerializer(params) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getCatalogItems): ${error}`);
    }
  },
  async getCatalogHot(params = {}) {
    const { badgeId, limit } = params

    try {
      return await axios.$get('/v1/catalog/products/hot', {
        params: {
          badge_id: badgeId,
          limit: limit || 4,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getCatalogHot): ${error}`);
    }
  },
  getProductGroups({
    type,
    page = 1,
    orderField = productGroupSortFields.CREATED_AT,
    orderDirection = sortDirections.DESC,
  }) {
    try {
      switch (type) {
        case productGroupTypes.LOOKBOOKS:
          return axios.$get('/v1/catalog/lookbooks', {
            params: {
              type_code: type,
              page,
              orderField,
              orderDirection,
            },
          });
        case productGroupTypes.SETS:
        case productGroupTypes.PROMO:
        case productGroupTypes.BRANDS:
          return axios.$get('/v1/catalog/product-groups', {
            params: {
              type_code: type,
              page,
              orderField,
              orderDirection,
            },
          });
      }
    } catch (error) {
      throw new Error(`API Request Error (getProductGroups): ${error}`);
    }
  },
  getFeed() {
    try {
      return axios.$get('/v1/catalog/feed');
    } catch (error) {
      throw new Error(`API Request Error (getFeed): ${error}`);
    }
  },
  getFrequentCategories() {
    try {
      return axios.$get('v1/categories/frequent');
    } catch (error) {
      throw new Error(`API Request Error (getFrequentCategories): ${error}`);
    }
  },
  getAllCategories() {
    try {
      return axios.$get('v1/categories');
    } catch (error) {
      throw new Error(`API Request Error (getAllCategories): ${error}`);
    }
  },
  getCategories(nodeCode, maxDepth, filter) {
    try {
      return axios.$get('v1/categories', {
        params: {
          node_code: nodeCode || undefined,
          max_depth: maxDepth || undefined,
          filter,
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getCategories): ${error}`);
    }
  },
  fetchFilters(payload) {
    try {
      return axios.$get('/v1/catalog/filter', {
        params: payload,
        paramsSerializer(params) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (fetchFilters): ${error}`);
    }
  },
});
