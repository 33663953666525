
import CustomScroll from '~/components/elements/CustomScroll';
import CompanySearchItem from '~/components/profile/company/Search/Item';

export default {
  name: 'CompanySearch',
  components: {CompanySearchItem, CustomScroll},
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
  },
}
