
export default {
  name: 'TreeMenu',
  components: {
    TreeMenuItem: () => import('~/components/elements/TreeMenuItem'),
    SpoilerWrapper: () => import('~/components/elements/Spoiler/SpoilerWrapper'),
    Expander: () => import('~/components/elements/Expander'),
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    isExpander: {
      type: Boolean,
      default: false,
    },
    expanderHeight: {
      type: Number,
      // default: 170,
      default: 202,
    },
  },
}
