
import {mapGetters, mapMutations} from 'vuex';
import env from '~/.env.json'
import HeaderTopLinks from '~/layouts/components/Header/Top/HeaderTopLinks';
import HeaderTopHelp from '~/layouts/components/Header/Top/HeaderTopHelp';

export default {
  name: 'HeaderTop',
  components: {HeaderTopHelp, HeaderTopLinks},
  data() {
    return {
      showHelpMenu: !env.HELP_MENU_DISABLED,
    }
  },
  computed: {
    ...mapGetters({
      menuTop: 'getMenuTop',
      getSelectedCity: 'geolocation/getSelectedCity',
    }),
  },
  methods: {
    ...mapMutations('geolocation', {
      setVisibleLocationModal: 'SET_VISIBLE_LOCATION_MODAL',
    }),
    openLocationModal() {
      this.setVisibleLocationModal(true);
    },
  },
}
