export const state = () => ({
  isOpenedSearch: false,
  isSearchPending: false,
  searchQuery: '',
  searchResult: {
    suggestions: [],
    products: [],
    range: 0,
  },
});

export const getters = {
  getSearchSuggestions: (state) => state.searchResult?.suggestions || [],
  getSearchProducts: (state) => state.searchResult?.products || [],
};

export const mutations = {
  SET_IS_OPENED_SEARCH(state, payload) {
    state.isOpenedSearch = payload;
  },
  SET_SEARCH_PENDING(state, payload) {
    state.isSearchPending = payload;
  },
  SET_SEARCH_QUERY(state, payload) {
    state.searchQuery = payload;
  },
  SET_SEARCH_RESULT(state, payload) {
    state.searchResult = payload;
  },
};

export const actions = {
  setIsOpenedSearch({ commit }, payload) {
    commit('SET_IS_OPENED_SEARCH', payload);
  },
  setSearchQuery({ commit, dispatch }, payload = '') {
    commit('SET_SEARCH_QUERY', payload);

    if (payload?.length > 2) {
      dispatch('search', payload)
    }
  },
  async search({ commit, dispatch }, query) {
    commit('SET_SEARCH_PENDING', false);

    if (!query) {
      dispatch('clearSearchResult')
      return
    }

    commit('SET_SEARCH_PENDING', true);

    const { suggestions, products, range } = await this.$api.search.search(query);

    if (suggestions.length > 4) {
      suggestions.length = 4
    }

    commit('SET_SEARCH_RESULT', { suggestions, products, range })
    commit('SET_IS_OPENED_SEARCH', true)
    commit('SET_SEARCH_PENDING', false);
  },
  clearSearchResult({ commit }, close = false) {
    commit('SET_SEARCH_QUERY', '')
    close && commit('SET_IS_OPENED_SEARCH', false)
    commit('SET_SEARCH_RESULT', { suggestions: [], products: [], range: 0 })
  },
  onInputFocus({ state, dispatch }) {
    if (state.searchQuery && !state.isOpenedSearch) {
      dispatch('search', state.searchQuery)
    }
  },
}
