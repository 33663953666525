
import HeaderTop from '~/layouts/components/Header/Top';
import HeaderMain from '~/layouts/components/Header/Main';
import HeaderBottom from '~/layouts/components/Header/Bottom';
import HeaderMobileMenu from '~/layouts/components/Header/MobileMenu';

export default {
  name: 'MainHeader',
  components: {
    HeaderTop,
    HeaderMain,
    HeaderBottom,
    HeaderMobileMenu,
  },
  props: {
    isShowHamburgerButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isScrollOffset: false,
      isOpenedMobileMenu: false,
      isShowCategories: false,
    };
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'mobileXs';
    },
    getBody() {
      return document.querySelector('body');
    },
  },
  watch: {
    mqIsMobile(value) {
      if (value) {
        if (this.isShowCategories) {
          this.isShowCategories = false
          this.isOpenedMobileMenu = true
        }
        return
      }

      if (this.isOpenedMobileMenu) {
        this.isOpenedMobileMenu = false
        this.isShowCategories = true
      }
    },
    $route() {
      this.scrollToTop();
      this.onLinkClick();
    },
    isOpenedMobileMenu(value) {
      value && (this.isShowCategories = false)
    },
  },
  mounted() {
    this.scrollToTop();
    const self = this;
    window.addEventListener('scroll', function() {
      self.isScrollOffset = window.scrollY > 110;
    });
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onLinkClick() {
      this.isShowCategories = false;
      this.isOpenedMobileMenu = false;
    },
    onChangeMobileMenu(value) {
      this.isOpenedMobileMenu = value;
    },
  },
};
