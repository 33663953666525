import {cookieNames} from '~/plugins/enums/cookie';

export default async function referralLink({ store, route, query, redirect, $cookies }) {
  const { refCode, ref } = query
  const code = ref || refCode

  const referral = $cookies.get(cookieNames.REFERRAL)

  if (code || (referral && !store.state.auth.referralCode)) {
    await store.dispatch('auth/setUserReferral', code || referral);
  }

  // Редирект здесь лишний
  // code && redirect({
  //   path: route.path,
  //   params: route.params,
  //   hash: route.hash,
  //   query: {
  //     ...route.query,
  //     ref: undefined,
  //     refCode: undefined,
  //   },
  // })
}
