
export default {
  name: 'CustomPopup',
  props: {
    popUpState: {
      type: Boolean,
      required: true,
    },
    animate: {
      type: String,
      default: '8.35rem',
    },
    top: {
      type: String,
      default: '5.4rem',
    },
  },
  computed: {
    popupStyles() {
      return {
        '--popup-enter': this.animate,
        '--popup-top': this.top,
      }
    },
  },
  methods: {
    closePopupHandler(e) {
      this.$emit('closePopUp', e)
    },
  },
}
