import qs from 'qs';
import { verificationCodeType } from '@/plugins/enums/auth';

export default (axios) => ({
  loadProfile() {
    try {
      return axios.$get('/v1/lk/profile');
    } catch (error) {
      throw new Error(`API Request Error (loadProfile): ${error}`);
    }
  },
  updatePersonalData(data) {
    try {
      return axios.$put('/v1/lk/profile/personal', data);
    } catch (error) {
      throw new Error(`API Request Error (updatePersonalData): ${error}`);
    }
  },
  sendNewReferralCode(data) {
    try {
      return axios.$put('/v1/lk/profile/referral-code', data);
    } catch (error) {
      throw new Error(`API Request Error (updatePersonalData): ${error}`);
    }
  },
  getProfileOrders({ sortDirection, sortKey, pageNum, perPage } = {}) {
    try {
      return axios.$get('/v1/lk/order', {
        params: {
          sortDirection: sortDirection ?? 'desc',
          sortKey: sortKey ?? 'number',
          pageNum: pageNum ?? 1,
          perPage: perPage ?? 12,
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getProfileOrders): ${error}`);
    }
  },
  getPromocodes(params) {
    try {
      return axios.$get('/v1/lk/promo-code', {
        params,
        paramsSerializer(params) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getProfilePreferences): ${error}`);
    }
  },
  getMessages(id) {
    try {
      let url;

      if (id) {
        url = `/v1/lk/messages/${id}`;
      } else {
        url = '/v1/lk/messages';
      }

      return axios.$get(url);
    } catch (error) {
      throw new Error(`API Request Error (getProfilePreferences): ${error}`);
    }
  },
  sendMessage(formData) {
    try {
      return axios.$post('/v1/lk/messages', formData);
    } catch (error) {
      throw new Error(`API Request Error (getProfilePreferences): ${error}`);
    }
  },
  sendMessageId(id, formData) {
    return axios.$post(`/v1/lk/messages/${id}`, formData);
  },
  getSubscriptions() {
    try {
      return axios.$get('/v1/lk/newsletter/subscriptions');
    } catch (error) {
      throw new Error(`API Request Error (getSubscriptions): ${error}`);
    }
  },
  getProfileBill() {
    try {
      return axios.$get('/v1/lk/bill');
    } catch (error) {
      throw new Error(`API Request Error (getProfileBill): ${error}`);
    }
  },
  createBillCard(payload)  {
    /* eslint-disable */
    const {
        card_panmask = payload.cardPanmask,
        card_synonim = payload.cardSynonim,
        card_country_code = payload.cardCountryCode,
        card_type = payload.cardType,
        account_number = payload.accountNumber,
    } = payload
    /* eslint-enable */

    try {
      return axios.post('/v1/lk/bill/ya-card', {
        card_panmask,
        card_synonim,
        card_country_code,
        card_type,
        account_number,
      });
    } catch (error) {
      throw new Error(`API Request Error (createBillCard): ${error}`);
    }
  },
  getProfileBillOperations({ pageNum, perPage } = {}) {
    try {
      return axios.$get('/v1/lk/bill/operations', {
        params: {
          pageNum: pageNum ?? 1,
          perPage: perPage ?? 12,
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getProfileBillOperations): ${error}`);
    }
  },
  getProfilePromo({ pageNum, perPage } = {}) {
    try {
      return axios.$get('/v1/lk/promo-page', {
        params: {
          pageNum: pageNum ?? 1,
          perPage: perPage ?? 12,
          v: new Date().getTime(),
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getProfilePromo): ${error}`);
    }
  },
  changePromoPageName(payload) {
    try {
      return axios.$put('/v1/lk/promo-page/name', payload);
    } catch (error) {
      throw new Error(`API Request Error (getSubscriptions): ${error}`);
    }
  },
  addPromoProductByLink(productCode) {
    try {
      return axios.$put(`/v1/lk/promo-page/products/by-code/${productCode}`);
    } catch (error) {
      throw new Error(`API Request Error (addPromoProductByLink): ${error}`);
    }
  },
  addPromoProductById(id) {
    try {
      return axios.$put(`/v1/lk/promo-page/products/by-id/${id}`);
    } catch (error) {
      throw new Error(`API Request Error (addPromoProductById): ${error}`);
    }
  },
  removePromoProductById(id) {
    try {
      return axios.$delete(`/v1/lk/promo-page/products/by-id/${id}`);
    } catch (error) {
      throw new Error(`API Request Error (removePromoProductById): ${error}`);
    }
  },
  getPromopageInfo(referralCode) {
    try {
      return axios.$get(`/v1/promo-page/${referralCode}`);
    } catch (error) {
      throw new Error(`API Request Error (getPromopageinfo): ${error}`);
    }
  },
  getByIdPromopageProducts({ id, pageNum, perPage } = {}) {
    try {
      return axios.$get(`/v1/promo-page/${id}/products`, {
        params: {
          pageNum: pageNum ?? 1,
          perPage: perPage ?? 12,
          v: new Date().getTime(),
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getByIdPromopageProducts): ${error}`);
    }
  },
  getPromopageAllProducts({ name, limit } = {}) {
    try {
      return axios.$get('/v1/lk/promo-page/products', {
        params: {
          limit: limit ?? 10,
          name: name || ' ',
          v: new Date().getTime(),
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getPromopageAllProducts): ${error}`);
    }
  },
  sendCashOutRequest(payload) {
    try {
      return axios.post('/v1/lk/bill/cash-out', payload);
    } catch (error) {
      throw new Error(`API Request Error (getProfileBillOperations): ${error}`);
    }
  },
  updateSubscriptions(payload) {
    try {
      return axios.$put('/v1/lk/newsletter/subscriptions/edit', payload);
    } catch (error) {
      throw new Error(`API Request Error (getSubscriptions): ${error}`);
    }
  },
  getProfileAllPreferences() {
    try {
      return axios.$get('/v1/lk/preferences/catalog');
    } catch (error) {
      throw new Error(`API Request Error (getProfilePreferences): ${error}`);
    }
  },
  getProfilePreferences(id) {
    try {
      return axios.$get(`/v1/lk/preferences/${id}`);
    } catch (error) {
      throw new Error(`API Request Error (getProfilePreferences/${id}): ${error}`);
    }
  },
  setProfileBrands(id, payload) {
    try {
      return axios.$put(`/v1/lk/preferences/${id}/brands`, payload);
    } catch (error) {
      throw new Error(`API Request Error (addProfileBrands/${id}): ${error}`);
    }
  },
  setProfileCategories(id, payload) {
    try {
      return axios.$put(`/v1/lk/preferences/${id}/categories`, payload);
    } catch (error) {
      throw new Error(`API Request Error (addProfilePersonalCategories/${id}: ${error}`);
    }
  },
  mergePreferences(payload) {
    try {
      return axios.$put('/v1/lk/preferences/merge', payload);
    } catch (error) {
      throw new Error(`API Request Error (mergePreferences): ${error}`);
    }
  },
  getProfileAddresses() {
    try {
      return axios.$get('/v1/lk/address');
    } catch (error) {
      console.error(error);
      throw new Error(`API request Error (getProfileAddresses): ${error}`);
    }
  },
  changeAddress(payload) {
    try {
      return axios.$put('/v1/lk/address', payload);
    } catch (error) {
      throw new Error(`API Request Error (changeAddress): ${error}`);
    }
  },
  removeAddress({ id }) {
    try {
      return axios.$delete(`/v1/lk/address/${id}`);
    } catch (error) {
      throw new Error(`API Request Error (removeAddress): ${error}`);
    }
  },
  changeDefaultAddress({ id }) {
    try {
      return axios.$put(`/v1/lk/address/${id}/default`);
    } catch (error) {
      throw new Error(`API Request Error (changeDefaultAddress): ${error}`);
    }
  },
  getPromotionProducts({ pageNum, perPage, isActive, search } = {}) {
    try {
      return axios.$get('/v1/lk/promotion-product', {
        params: {
          pageNum: pageNum || 1,
          perPage: perPage || 12,
          isActive: isActive || 0,
          search: search || undefined,
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getPromotionProducts): ${error}`);
    }
  },
  getProfileBonuses({ pageNum, perPage } = {}) {
    try {
      return axios.get('/v1/lk/bonuses', {
        params: {
          pageNum: pageNum ?? 1,
          perPage: perPage ?? 12,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getProfileBonuses): ${error}`);
    }
  },
  getProfileCertificates() {
    try {
      return axios.$get('/v1/certificate');
    } catch (error) {
      console.error(error);
      throw new Error(`API request Error (getProfileCertificates): ${error}`);
    }
  },
  getProfileDocuments({ type }) {
    try {
      return axios.$get('/v1/lk/documents', {
        params: {
          type: type || undefined,
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      });
    } catch (error) {
      console.error(error);
      throw new Error(`API request Error (getProfileDocuments): ${error}`);
    }
  },
  activateCertificatePin(payload) {
    try {
      return axios.$post('/v1/certificate/activate', payload);
    } catch (error) {
      console.log(error);
      throw new Error(`API request Error (activateCertificatePin): ${error}`);
    }
  },
  getProfileReturns({ sortDirection, sortKey, pageNum, perPage } = {}) {
    try {
      return axios.$get('/v1/lk/returns', {
        params: {
          sortDirection: sortDirection ?? 'desc',
          sortKey: sortKey ?? 'number',
          pageNum: pageNum ?? 1,
          perPage: perPage ?? 12,
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getProfileReturns): ${error}`);
    }
  },
  getProfileOrder(id) {
    return axios.$get(`/v1/lk/order/${id}`);
  },
  sendCode(payload) {
    try {
      let url = '';
      const data = {};

      switch (payload.type) {
        case verificationCodeType.PROFILE_PHONE:
          url = '/v1/lk/profile/change-phone-code';
          data.phone = payload.destination;
          break;
        case verificationCodeType.PROFILE_EMAIL:
          url = '/v1/lk/profile/change-email-code';
          data.email = payload.destination;
          break;
        default:
          throw new Error(`Wrong verification code type (sendCode): ${payload.type}`);
      }
      return axios.$post(url, data);
    } catch (error) {
      throw new Error(`API Request Error (sendCode): ${error}`);
    }
  },
  updateCredential(payload) {
    try {
      let url = '';
      const data = {
        code: payload.code,
      };

      switch (payload.type) {
        case verificationCodeType.PROFILE_PHONE:
          url = '/v1/lk/profile/change-phone';
          break;
        case verificationCodeType.PROFILE_EMAIL:
          url = '/v1/lk/profile/change-email';
          break;
        default:
          throw new Error(`Wrong verification code type (updateCredential): ${payload.type}`);
      }
      return axios.$post(url, data);
    } catch (error) {
      throw new Error(`API Request Error (updateCredential): ${error}`);
    }
  },
  getPaymentLinkOrder(id) {
    try {
      return axios.$get(`/v1/checkout/payment-link/${id}`);
    } catch (error) {
      throw new Error(`API Request Error (getPaymentLinkOrder): ${error}`);
    }
  },
  cancelOrder(id, { reasonId, text }) {
    try {
      return axios.$put(`/v1/lk/order/${id}/cancel`, {
        reasonId,
        text,
      });
    } catch (error) {
      throw new Error(`API Request Error (cancelOrder): ${error}`);
    }
  },
  repeatOrder(id) {
    try {
      return axios.$post(`/v1/lk/order/${id}/repeat`);
    } catch (error) {
      throw new Error(`API Request Error (repeatOrder): ${error}`);
    }
  },
  addItemsReturn(id, payload) {
    try {
      return axios.$post(`/v1/lk/returns/add-items/${id}`, payload);
    } catch (error) {
      throw new Error(`API Request Error (addItemsReturn): ${error}`);
    }
  },
  getReturn(id) {
    try {
      return axios.$get(`/v1/lk/returns/${id}`);
    } catch (error) {
      throw new Error(`API Request Error (getReturn): ${error}`);
    }
  },
  returnCancel(id) {
    try {
      return axios.$post(`/v1/lk/returns/${id}/cancel`);
    } catch (error) {
      throw new Error(`API Request Error (returnCancel): ${error}`);
    }
  },
  getCurrentReturn(id) {
    try {
      return axios.$get(`/v1/lk/returns/current/items/${id}`);
    } catch (error) {
      throw new Error(`API Request Error (getCurrentReturn): ${error}`);
    }
  },
  getReasonsReturn(id) {
    try {
      return axios.$get(`/v1/lk/returns/reasons/${id}`);
    } catch (error) {
      throw new Error(`API Request Error (getReasonsReturn): ${error}`);
    }
  },
  updateItemReturn(idOrder, idProduct, payload) {
    try {
      return axios.$post(`/v1/lk/returns/${idOrder}/update-item/${idProduct}`, payload);
    } catch (error) {
      throw new Error(`API Request Error (updateItemReturn): ${error}`);
    }
  },
  setReturnRoute(id) {
    try {
      return axios.$get(`/v1/lk/returns/current/set-route/${id}`);
    } catch (error) {
      throw new Error(`API Request Error (setReturnRoute): ${error}`);
    }
  },
  sendReturn(id) {
    try {
      return axios.$post(`/v1/lk/returns/${id}/send`);
    } catch (error) {
      throw new Error(`API Request Error (sendReturn): ${error}`);
    }
  },
  calculateCourierReturn(id, payload) {
    try {
      return axios.$post(`/v1/lk/returns/${id}/calculate-courier`, payload);
    } catch (error) {
      throw new Error(`API Request Error (calculateCourierReturn): ${error}`);
    }
  },
  updateDeliveryReturn(idReturn, payload) {
    try {
      return axios.$post(`/v1/lk/returns/${idReturn}/update-delivery`, payload);
    } catch (error) {
      throw new Error(`API Request Error (updateDeliveryReturn): ${error}`);
    }
  },
  getPointsPickup(idReturn, from, to) {
    try {
      return axios.$get(`/v1/lk/returns/${idReturn}/points`, {
        params: {
          pointFrom: from,
          pointTo: to,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getPointsPickup): ${error}`);
    }
  },
  calculatePickupPoint(idReturn, idPoint) {
    try {
      return axios.$post(`v1/lk/returns/${idReturn}/calculate-point/${idPoint}`);
    } catch (error) {
      throw new Error(`API Request Error (calculatePickupPoint): ${error}`);
    }
  },
  getPointInfo(idPoint) {
    try {
      return axios.$get(`v1/points/${idPoint}`);
    } catch (error) {
      throw new Error(`API Request Error (getPointInfo): ${error}`);
    }
  },
  changeProfileAvatar(formData) {
    try {
      return axios.$post('/v1/lk/profile/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data; boundary="boundary',
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (changeProfileAvatar): ${error}`);
    }
  },
  deleteProfileAvatar() {
    try {
      return axios.delete('/v1/lk/profile/avatar');
    } catch (error) {
      throw new Error(`API Request Error (deleteProfileAvatar): ${error}`);
    }
  },
  changeProfileLegalInfo(payload) {
    try {
      return axios.put('/v1/lk/profile/legal-info', payload);
    } catch (error) {
      throw new Error(`API Request Error (changeProfileLegalInfo): ${error}`);
    }
  },
  getReferralData() {
    try {
      return axios.$get('/v1/lk/order-referral');
    } catch (error) {
      throw new Error(`API Request Error (getReferralData): ${error}`);
    }
  },

  getReferralOrders(pageNum, perPage, sortKey = 'order_date', sortDirection = 'desc', filterOrderDate) {
    try {
      return axios.$get('/v1/lk/order-referral/orders', {
        params: {
          pageNum,
          perPage,
          sortKey,
          sortDirection,
          filterOrderDate,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getReferralOrders): ${error}`);
    }
  },

  getReferralOrderDetails(id, sortKey = 'order_date', sortDirection = 'desc') {
    try {
      return axios.$get(`/v1/lk/order-referral/${id}`, {
        params: {
          sortKey,
          sortDirection,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getReferralOrderDetails): ${error}`);
    }
  },
  getBusinessTypes() {
    try {
      return axios.$get('/v1/lk/business');
    } catch (error) {
      throw new Error(`API Request Error (getBusinessTypes): ${error}`);
    }
  },
  setUserOfferAccept(accept) {
    try {
      return axios.$put('/v1/lk/profile/offer-accept', {
        is_offer_accepted: accept,
      });
    } catch (error) {
      throw new Error(`API Request Error (setUserOfferAccept): ${error}`);
    }
  },

  getCompanyAccount() {
    try {
      return axios.$get('/v1/lk/profile/legal-entities');
    } catch (error) {
      console.log(error);
      throw new Error(`API request Error (getCompanyAccount): ${error}`);
    }
  },
  addCompanyAccount(payload) {
    const company = {
      legal_info_company_name: payload.name,
      legal_info_company_address: payload.address,
      legal_info_inn: payload.inn,
    }
    try {
      return axios.$post('/v1/lk/profile/add-account', company);
    } catch (error) {
      console.log(error);
      throw new Error(`API request Error (addCompanyAccount): ${error}`);
    }
  },
  changeCompanyAccount(id) {
    try {
      return axios.$get(`/v1/lk/profile/change-account/${id}`);
    } catch (error) {
      console.log(error);
      throw new Error(`API request Error (changeCompanyAccount): ${error}`);
    }
  },
  deleteCompanyAccount(id) {
    try {
      return axios.$delete(`/v1/lk/profile/delete-account/${id}`);
    } catch (error) {
      console.log(error);
      throw new Error(`API request Error (changeCompanyAccount): ${error}`);
    }
  },

  getReceivers() {
    try {
      return axios.$get('/v1/lk/receivers');
    } catch (error) {
      console.log(error);
      throw new Error(`API request Error (getReceivers): ${error}`);
    }
  },
  getReceiver(id) {
    try {
      return axios.$get(`/v1/lk/receivers/${id}`);
    } catch (error) {
      console.log(error);
      throw new Error(`API request Error (getReceiver): ${error}`);
    }
  },
  createReceiver(payload) {
    try {
      return axios.$post('/v1/lk/receivers', payload);
    } catch (error) {
      console.log(error);
      throw new Error(`API request Error (createReceiver): ${error}`);
    }
  },
  receiverUpload(payload) {
    try {
      return axios.$post('/v1/lk/receivers/upload', payload);
    } catch (error) {
      console.log(error);
      throw new Error(`API request Error (receiverUpload): ${error}`);
    }
  },
  updateReceiver(id, payload) {
    try {
      return axios.$put(`/v1/lk/receivers/${id}`, payload);
    } catch (error) {
      console.log(error);
      throw new Error(`API request Error (updateReceiver): ${error}`);
    }
  },
  removeReceiver(id) {
    try {
      return axios.$delete(`/v1/lk/receivers/${id}`);
    } catch (error) {
      console.log(error);
      throw new Error(`API request Error (removeReceiver): ${error}`);
    }
  },
});
