export default axios => ({
  getFavoritesAll() {
    try {
      return axios.$get('/v1/lk/favorites/all');
    } catch (error) {
      throw new Error(`API Request Error (getFavoritesAll): ${error}`);
    }
  },
  getFavorites(payload) {
    try {
      return axios.$get('/v1/lk/favorites', {
        params: payload,
      });
    } catch (error) {
      throw new Error(`API Request Error (getFavorites): ${error}`);
    }
  },
  addFavoritesItem(productId, byOffer = false) {
    try {
      return axios.$post(`/v1/lk/favorites/${productId}`, null, {
        params: {
          byOffer: byOffer || undefined,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (addFavoritesItem): ${error}`);
    }
  },
  deleteFavoritesItem(productId) {
    try {
      return axios.$delete(`/v1/lk/favorites/${productId}`);
    } catch (error) {
      throw new Error(`API Request Error (deleteFavoritesItem): ${error}`);
    }
  },
  deleteFavoritesAll() {
    try {
      return axios.$delete('/v1/lk/favorites/all');
    } catch (error) {
      throw new Error(`API Request Error (deleteFavoritesAll): ${error}`);
    }
  },
});
