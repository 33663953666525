
export default {
  name: 'ProfileInitials',
  props: {
    initialsIcon: {
      type: String,
      default: '',
    },
    initials: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    nameClass: {
      type: String,
      default: '',
    },
    initialsClass: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
  },
}
