export default axios => ({
  checkSession(config) {
    try {
      return axios.$get('/v1/auth/is-login', config);
    } catch (error) {
      throw new Error(`API Request Error (checkSession): ${error}`);
    }
  },
  async setSessionReferralCode(refCode) {
    try {
      return await axios.$post('/v1/auth/referralCode', { referral_code: refCode });
    } catch (error) {
      throw new Error(`API Request Error (setSessionReferralCode): ${error}`);
    }
  },
  loginByPhoneSendSms(payload) {
    try {
      return axios.$post('/v1/auth/loginByPhone/sendSMS', payload);
    } catch (error) {
      throw new Error(`API Request Error (loginByPhoneSendSms): ${error}`);
    }
  },
  loginByPhoneCheckCode(payload) {
    try {
      return axios.$post('/v1/auth/loginByPhone/checkCode', payload);
    } catch (error) {
      throw new Error(`API Request Error (loginByPhoneCheckCode): ${error}`);
    }
  },
  getSocialLink(payload) {
    try {
      return axios.$post('/v1/auth/getSocialLink', payload);
    } catch (error) {
      throw new Error(`API Request Error (getSocialLink): ${error}`);
    }
  },
  loginBySocial(driver, params) {
    try {
      return axios.$get(`/v1/auth/socialHandler/${driver}`, params);
    } catch (error) {
      throw new Error(`API Request Error (loginBySocial): ${error}`);
    }
  },
  logout() {
    try {
      return axios.$post('/v1/auth/logout');
    } catch (error) {
      throw new Error(`API Request Error (logout): ${error}`);
    }
  },
  getUser() {
    try {
      return axios.$get('/v1/auth/user');
    } catch (error) {
      throw new Error(`API Request Error (getUser): ${error}`);
    }
  },
  getUserNameAvatar() {
    try {
      return axios.$get('/v1/auth/user-name-avatar');
    } catch (error) {
      throw new Error(`API Request Error (getUserNameAvatar): ${error}`);
    }
  },
  loginByPassword(payload) {
    try {
      return axios.$post('/v1/auth/loginByPassword', payload);
    } catch (error) {
      throw new Error(`API Request Error (loginByPassword): ${error}`);
    }
  },
});
