
import {mapState} from 'vuex';
import env from '@/.env.json';
import CustomScroll from '~/components/elements/CustomScroll';
import CustomSidebar from '~/components/elements/CustomSidebar';
import TreeMenu from '~/components/elements/TreeMenu';

export default {
  name: 'HeaderBottomCatalog',
  components: {
    TreeMenu,
    CustomSidebar,
    CustomScroll,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isGiftCardItem: !!env?.GIFTCARD,
      activeCategory: '',
    }
  },
  computed: {
    ...mapState('catalog', ['allCategories']),
    treeMenu() {
      function splitArray(array) {
        const subArrays = [[], [], []];

        for (let i = 0; i < array.length; i++) {
          const subarrayIndex = i % 3;
          subArrays[subarrayIndex].push(array[i]);
        }

        return subArrays;
      }

      function transformTree(category, split = false) {
        const item = {
          id: category.id,
          code: category.code,
          url: `/catalog/${category.code}`,
          name: category.name,
          image: category.image,
          disabled: category.disabled,
        }

        if (!category?.items) return item

        if (!split) {
          item.sublist = category.items.map(item => transformTree(item, false))
        } else {
          const list = splitArray(category.items)

          item.list = list.map(list => {
            return list.map(item => transformTree(item, false))
          })
        }

        return item
      }

      return this.allCategories.map(item => transformTree(item, true))
    },
  },
  watch: {
    isOpen() {
      this.onCategoryMouseEnterHandler(this.treeMenu[0]?.code)
    },
  },
  beforeMount() {
    this.onCategoryMouseEnterHandler(this.treeMenu[0]?.code)
  },
  methods: {
    onLinkClick() {
      this.$emit('linkClick')
      this.onCategoryMouseEnterHandler(this.treeMenu[0]?.code)
    },
    onCategoryMouseEnterHandler(category) {
      this.activeCategory = category || ''
    },
  },

}
