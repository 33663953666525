
import { mapMutations, mapState, mapGetters } from 'vuex';
import { authModalPages } from '@/plugins/enums/auth';
import { MODAL_FADE_DURATION, TIME_CODE_SMS } from '@/plugins/config';

import CustomModal from '@/components/elements/CustomModal';
import SignUpPhone from '@/components/auth/Modal/SignUp/Phone';
import SignUpCode from '@/components/auth/Modal/SignUp/Code';
import Location from '@/components/auth/Location';

export default {
  name: 'AuthModal',
  components: {
    CustomModal,
    Location,
    SignUpPhone,
    SignUpCode,
  },
  data() {
    return {
      modalFadeDuration: MODAL_FADE_DURATION,
      authModalPages,
      remainingTime: 0,
    };
  },
  computed: {
    ...mapState('auth', ['isOpenedAuthModal']),
    ...mapGetters({
      getCurrentAuthModalPage: 'auth/getCurrentAuthModalPage',
    }),
  },
  methods: {
    ...mapMutations('auth', {
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    closeModal() {
      this.setIsOpenedAuthModal(false);
      setTimeout(this.resetCurrentAuthModalPage, this.modalFadeDuration);

      this.$emit('closeModal')
    },
    resetCurrentAuthModalPage() {
      this.setCurrentAuthModalPage(null);
    },
    startTimer() {
      this.remainingTime = TIME_CODE_SMS;
      const intervalId = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
        } else {
          clearInterval(intervalId);
        }
      }, 1000);
    },
  },
};
