
import { directive } from 'vue-awesome-swiper';
import CoolLightBox from 'vue-cool-lightbox';

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import 'swiper/css/swiper.css'
import CustomImage from '~/components/elements/CustomImage';
import {
  generateYoutubePreview,
  generateYoutubeVideoSourcePath,
} from '~/plugins/helpers/file';
import VideoIcon from '~/components/elements/VideoIcon';

export default {
  name: 'ProductSliderImages',
  components: {
    VideoIcon,
    CustomImage,
    CoolLightBox,
  },
  directives: {
    swiper: directive,
  },
  props: {
    id: {
      type: String,
      default: `id_${String(Math.random()).slice(2)}`,
    },
    images: {
      type: Array,
      required: true,
    },
    controls: {
      type: Boolean,
      required: false,
    },
    alt: {
      type: String,
      default: '',
    },
    video: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sliderOptionsMain: {
        slidesPerView: 1,
        pagination: {
          el: `.${this.id} .product-images-slider__dotts`,
          clickable: false,
        },
        navigation: {
          nextEl: `.${this.id} .product-images-slider__controls-arrow_next`,
          prevEl: `.${this.id} .product-images-slider__controls-arrow_prev`,
        },
      },
      imageOpenIndex: null,
    }
  },
  computed: {
    pngImages() {
      let slides = []

      slides = this.images.map(image => {
        return {
          url: image.url,
          type: 'image',
        }
      });

      if (this.video) {
        slides.push({
          src: generateYoutubePreview(this.video),
          type: 'youtube',
        })
      }

      return slides
    },
    galleryItems() {
      let slides = []

      slides = this.images.map(image => {
        return {
          src: image.url.large,
        }
      })

      if (this.video) {
        slides.push({ src: generateYoutubeVideoSourcePath(this.video) })
      }

      return slides
    },
  },
  methods: {
    openImage(index) {
      this.imageOpenIndex = index;
    },
  },
}
