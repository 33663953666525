import isEqual from 'date-fns/isEqual'


export const isEqualDates = (firstDate, secondDate) => {
  const firstDateFormatted = new Date(firstDate);
  const secondDateFormatted = new Date(secondDate);
  firstDateFormatted.setHours(0, 0, 0, 0);
  secondDateFormatted.setHours(0, 0, 0, 0);

  return isEqual(firstDateFormatted, secondDateFormatted);
}

export const isToday = (someDate) => {
  return isEqualDates(someDate, new Date())
}

export function getDate(string) {
  // Safari не умеет парсить даты вида yyyy-mm-dd, так что заменяем "-" на "/"
  return new Date(string.replace(/-/g, '/'));
}

export function getLocaleDate(date) {
  return new Date(date).toLocaleDateString()
}
