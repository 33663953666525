export default axios => ({
  getCartItems() {
    try {
      return axios
        .$get('/v1/cart/data', {
          headers: { 'Cache-Control': 'no-cache' },
        })
    } catch (error) {
      throw new Error(`API Request Error (getCartItems): ${error}`);
    }
  },
  addToCartItem(payload) {
    try {
      return axios.$post('/v1/cart/item', payload);
    } catch (error) {
      throw new Error(`API Request Error (addToCartItem): ${error}`);
    }
  },
  addToCartBundle(payload) {
    try {
      return axios.$post('/v1/cart/item-bundle', payload);
    } catch (error) {
      throw new Error(`API Request Error (addToCartBundle): ${error}`);
    }
  },
  addToCartService(payload) {
    try {
      return axios.$post('/v1/cart/item-public-event', payload);
    } catch (error) {
      throw new Error(`API Request Error (addToCartService): ${error}`);
    }
  },
  removeCartService(payload) {
    try {
      return axios.$delete('/v1/cart/item-public-event', payload);
    } catch (error) {
      throw new Error(`API Request Error (addToCartService): ${error}`);
    }
  },
  removeCartItem(config) {
    try {
      return axios.$delete('/v1/cart/item', config);
    } catch (error) {
      throw new Error(`API Request Error (removeCartItem): ${error}`);
    }
  },
  removeCartBundle(config) {
    try {
      return axios.$delete('/v1/cart/item-bundle', config);
    } catch (error) {
      throw new Error(`API Request Error (removeCartBundle): ${error}`);
    }
  },
  async checkCartsDifference() {
    try {
      const { different } = await axios.$get('/v1/cart/difference');
      return different;
    } catch (error) {
      console.error(`API Request Error (checkCartsDifference): ${error}`);
      throw error;
    }
  },
  async sendMergeCartsOption(action) {
    await axios.$post('/v1/cart/merge', {
      action,
    });
  },
  checkCart(cartType) {
    try {
      return axios.$get('/v1/cart/check-basket', {
        params: {
          type_cart: cartType,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (checkCart): ${error}`);
    }
  },
  async getCartItemsCount() {
    try {
      const { count } = await axios.$get('/v1/cart/count-items');
      return parseInt(count);
    } catch (error) {
      throw new Error(`API Request Error (getCartItemsCount): ${error}`);
    }
  },
  addCartPromocode(payload) {
    try {
      return axios.$post('/v1/cart/promo-code', payload);
    } catch (error) {
      throw new Error(`API Request Error (addCartPromocode): ${error}`);
    }
  },
  deleteCartPromocode(payload) {
    try {
      return axios.$post('/v1/cart/promo-code', payload);
    } catch (error) {
      throw new Error(`API Request Error (deleteCartPromocode): ${error}`);
    }
  },

});
