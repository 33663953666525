export default async function loginBySocial({ route, store }) {
  try {
    const data = {
      driver: route.params.driver,
      query: route.query,
    };

    await store.dispatch('auth/loginBySocial', data);
  } catch (error) {
    console.error(error);
  }
}
