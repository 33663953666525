
import {mapActions, mapGetters, mapState} from 'vuex';
import SearchInput from '~/layouts/components/Search/Input';
import CustomPopup from '~/components/elements/CustomPopup';
import SearchResult from '~/layouts/components/Search/Result';
import SearchResultProducts from '~/layouts/components/Search/Result/Products';
import CustomModal from '~/components/elements/CustomModal';
import SearchResultEmpty from '~/layouts/components/Search/Result/Empty';

export default {
  name: 'HeaderMainSearch',
  components: {SearchResultEmpty, CustomModal, SearchResultProducts, SearchResult, CustomPopup, SearchInput},
  props: {
    isScrollOffset: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('search', ['searchQuery', 'isOpenedSearch', 'isSearchPending']),
    ...mapGetters({
      getSearchSuggestions: 'search/getSearchSuggestions',
      getSearchProducts: 'search/getSearchProducts',
    }),
    mqIsTablet() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    mqIsMobile() {
      return this.$mq === 'mobileXs';
    },
    isPopupSearch() {
      if (this.mqIsMobile) return false

      return !this.isScrollOffset || this.mqIsTablet
    },
    isSearchEmpty() {
      return this.searchQuery.length > 2 && !this.isSearchPending
    },
  },
  watch: {
    '$route'() {
      if (this.isOpenedSearch) {
        this.clearSearchResult(true)
      }
    },
    isPopupSearch(value) {
      if (value && !this.searchQuery && this.isOpenedSearch) {
        this.clearSearchResult(true)
      }
    },
  },
  methods: {
    ...mapActions('search', ['setIsOpenedSearch', 'setSearchQuery', 'clearSearchResult' ]),
  },
}
