import {
  helpers,
  required as r,
} from 'vuelidate/lib/validators';

import {countCheckdigit} from '~/plugins/helpers/utils';


function validateSnils(snils) {
  if (typeof snils === 'number') {
    snils = snils.toString();
  } else if (typeof snils !== 'string') {
    snils = '';
  }

  const str = snils.replace(/\D/g, '');

  if (!str.length || str.length !== 11 || /[^0-9]/.test(str)) {
    return false
  }

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(str[i]) * (9 - i);
  }
  let checkDigit = 0;

  if (sum < 100) {
    checkDigit = sum;
  } else if (sum > 101) {
    checkDigit = parseInt(sum % 101);
    if (checkDigit === 100) {
      checkDigit = 0;
    }
  }

  return checkDigit === parseInt(str.slice(-2));
}

function innValidation(value) {
  const inn = value || '';

  if (!inn) return true

  let isCorrect = false;
  let checkdigit;
  let firstCheckdigit;
  let secondCheckdigit;

  /* ИНН может быть 10 или 12-значным и в каждом случае имеет свою логику проверки */
  switch (inn.length) {
    case 10:
      checkdigit = countCheckdigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
      if (checkdigit === parseInt(inn[9], 10)) isCorrect = true;
      break;
    case 12:
      firstCheckdigit = countCheckdigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
      secondCheckdigit = countCheckdigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
      if (firstCheckdigit === parseInt(inn[10], 10) && secondCheckdigit === parseInt(inn[11], 10))
        isCorrect = true;
      break;
    default:
  }

  return isCorrect;
}

function validateKpp(kpp, error) {
  let isCorrect = false;

  if (typeof kpp === 'number') {
    kpp = kpp.toString();
  } else if (typeof kpp !== 'string') {
    kpp = '';
  }

  if (!kpp.length) {
    error.code = 1;
    error.message = 'КПП пуст';
  } else if (kpp.length !== 9) {
    error.code = 2;
    error.message = 'КПП может состоять только из 9 знаков (цифр или заглавных букв латинского алфавита от A до Z)';
  } else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
    error.code = 3;
    error.message = 'Неправильный формат КПП';
  } else {
    isCorrect = true;
  }
  return isCorrect;
}

// Рассчетный счет
function rsValidation(rs, bik) {
  if (!bik) bik = '';
  if (!rs) rs = '';
  const bikRs = bik.toString().slice(-3) + rs;
  let checksum = 0;
  const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];

  coefficients.forEach((item, index) => {
    checksum += coefficients[index] * (bikRs[index] % 10)
  })

  return checksum % 10 === 0;
}

function rsWithParams(prop) {
  return helpers.withParams({ type: 'rs', bik: prop }, function (value, parentVm) {
    return rsValidation(value, helpers.ref(prop, this, parentVm));
  });
}

// Бик
function bikValidation(bik) {
  if (!bik) bik = '';
  if (bik instanceof Number) bik = bik.toString();
  return bik.length === 9 && !/[^0-9]/.test(bik);
}

function passportValidation(passport) {
  if (!passport) passport = '';

  if (passport instanceof Number) passport = passport.toString();

  const regex = /^\d{10}$/
  return regex.test(passport)
}

export const passport = passportValidation;
export const bik = bikValidation;
export const rs = rsWithParams;
export const inn = innValidation;
export const kpp = validateKpp;
export const snils = validateSnils;
export const required = r;
