
import { mapActions, mapState } from 'vuex';
import Sidebar from '@/layouts/components/Sidebar';

export default {
  name: 'SidebarWrapper',
  components: {
    Sidebar,
  },
  computed: {
    ...mapState({ isOpenedSidebar: 'isOpenedSidebar' }),
  },
  methods: {
    ...mapActions({ setIsOpenedSidebar: 'setIsOpenedSidebar' }),
    closeSidebar() {
      this.setIsOpenedSidebar(false);
    },
  },
};
