
import {mapActions} from 'vuex';
import SearchResultSuggestions from '~/layouts/components/Search/Result/Suggestions';
import CustomScroll from '~/components/elements/CustomScroll';

export default {
  name: 'SearchResult',
  components: {CustomScroll, SearchResultSuggestions},
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
    itemClass: {
      type: String,
      default: '',
    },
    itemIcon: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    ...mapActions('search', ['setIsOpenedSearch', 'setSearchQuery', 'clearSearchResult' ]),
    onClickSuggestion(suggestion) {
      this.clearSearchResult()
      this.$router.push({
        name: 'search',
        query: { search_string: suggestion || this.searchQuery },
      });

      this.$emit('suggestion', suggestion)
    },
    onCloseHandler() {
      this.$emit('close')
    },
  },
}
