export function getReceiversData(data) {
  return data.map(getReceiverData)
}

export function getReceiverData(data) {
  return {
    id: data.id,
    name: `${data.last_name} ${data.first_name}`,
    phone: data.phone,
    email: data.email,
    post: data.post,
    isFile: data.is_file,
  }
}

export function getReceiverPayload(recipient, fileId = null) {
  const [ lastName, ...name ]  = recipient.name.split(' ')
  const firstName = name.join(' ')

  const payload = {
    first_name: firstName,
    last_name: lastName,
    phone: recipient.phone,
    email: recipient.email,
    post: recipient.post,
  }

  if (fileId) {
    payload.file_id = fileId
  }

  return payload
}

