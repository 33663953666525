

import SearchResultProductsItem from '~/layouts/components/Search/Result/Products/Item';
export default {
  name: 'SearchResultProducts',
  components: {SearchResultProductsItem},
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onClickHandler(item) {
      this.$emit('close')
    },
  },
}
