export default async function createCard({query, store, redirect}) {
  const {
    skr_destinationCardPanmask: cardPanmask,
    skr_destinationCardSynonim: cardSynonim,
    skr_destinationCardCountryCode: cardCountryCode,
    skr_destinationCardType: cardType,
    accountNumber,
    identificationStatus,
  } = query;

  if (cardPanmask) {
    await store.dispatch('profile/createCard', {
      identificationStatus,
      cardPanmask,
      cardSynonim,
      cardCountryCode,
      cardType,
      accountNumber,
    });

    return redirect({ name: 'profile.pro-bill' });
  }
}
