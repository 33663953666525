export function getProductDemensions(product) {
  if (product?.width && product?.height) {
    let value = `${product.width} × ${product.height}`;

    if (product.length) {
      value += ` × ${product.length}`;
    }

    return {
      name: 'Размер',
      value: `${value} см`,
    };
  }

  return null;
}
