
import CustomLink from '~/components/elements/CustomLink';

export default {
  name: 'HeaderTopLinks',
  components: {
    CustomLink,
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
}
