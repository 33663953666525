
export default {
  name: 'CustomImagePicture',
  props: {
    src: {
      type: String,
      required: true,
    },
    retina: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    media: {
      type: [Array, null],
      default: null,
    },
    imageLQIP: {
      type: String,
      default: '/assets/images/lazyimage.png',
    },
  },
  data() {
    return {
      pixelRatio: null,
      breakpoints: [],
      isViewport: false,
      lazyClass: this.loading !== 'lazy' ? 'isLoaded' : '',
      observer: null,
    }
  },
  computed: {
    imageMedia() {
      return this.media.map(item => {
        return {
          image: item.src,
          media: item.media,
        }
      })
    },
    retinaSrc() {
      if (!this.retina || !this.isViewport && this.isLazyLoad) return `${this.imageLQIP} 2x`

      return `${this.retina} 2x`
    },
    imageSrc() {
      if (!this.isViewport && this.isLazyLoad) {
        return {
          src: this.imageLQIP,
        }
      }

      return {
        src: this.src,
      }
    },
    isLazyLoad() {
      return this.loading === 'lazy'
    },
  },
  mounted() {
    this.pixelRatio = window.devicePixelRatio

    if (this.isLazyLoad) {
      this.observer = new IntersectionObserver((entries, obs) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.isViewport = true

            obs.unobserve(entry.target);
            this.observer?.disconnect();
            this.observer = null;
          }
        });
      }, {});

      this.observer.observe(this.$refs.picture);
    }
  },
  beforeDestroy() {
    this.breakpoints.length = 0

    if (this.isLazyLoad) {
      this.observer?.disconnect();
      this.observer = null;
    }
  },
  methods: {
    onImgLoad() {
      if (!this.isViewport && this.isLazyLoad) return

      this.lazyClass = 'isLoaded'
      this.$emit('load')
    },
  },
}
