
export default {
  name: 'CustomLink',
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
  },
  computed: {
    isExternalLink() {
      return typeof this.to === 'string' && /^https?:\/\//.test(this.to);
    },
  },
}
