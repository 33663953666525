import {dateFormat} from '~/plugins/formatting';

function prepareOffer(offer, categories, brands) {
  return {
    ...offer,
    category: categories[offer.category_id],
    brand: brands[offer.brand_id],
  };
}

const dateSettings = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
}

export const state = () => ({
  items: [],
  categories: {},
  brands: {},
  offers: {},
})

export const getters = {
  promocodes({ items, categories = {}, brands = {}, offers = {} }) {

    return items.map(item => {
      const itemOffers =
        item.discountInfo && item.discountInfo.offers.map((o) => prepareOffer(offers[o], categories, brands));

      const offersMap = {};
      if (itemOffers)
        // eslint-disable-next-line no-restricted-syntax
        for (const offer of itemOffers) {
          const key = `${offer.category.name} ${offer.brand.name}`;
          if (!offersMap[key]) offersMap[key] = { id: key, isOpen: false, items: [] };
          offersMap[key].items.push(offer);
        }

      return {
        type: item.type,
        code: item.code,
        startDate: item.start_date && dateFormat(item.start_date, dateSettings),
        endDate: item.end_date && dateFormat(item.end_date, dateSettings),
        discount: item.discountInfo && item.discountInfo.value,
        discountType: item.discountInfo && item.discountInfo.type,
        brands: item.discountInfo && {
          isOpen: false,
          items: item.discountInfo.brands.map((b) => brands[b]),
        },
        categories: item.discountInfo && {
          isOpen: false,
          items: item.discountInfo.categories.map((c) => categories[c]),
        },
        offers: offersMap,
      }
    });
  },
}

export const actions = {
  async getPromocodeData({ commit }, isArchive) {
    try {
      const data = await this.$api.profile.getPromocodes({ archive: isArchive });
      commit('SET_PROMOCODES_DATA', data);
    } catch (error) {
      console.log(error)
    }
  },
}

export const mutations = {
  SET_PROMOCODES_DATA(state, payload) {
    const { promo_codes: items, categories, brands, offers } = payload;

    state.items = items || [];
    state.categories = categories || {};
    state.brands = brands || {};
    state.offers = offers || {};
  },
}
