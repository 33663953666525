
import { mapGetters, mapActions, mapMutations } from 'vuex';
import LocationSelection from '@/components/auth/LocationSelection';
import Button from '@/components/elements/Button';

export default {
  name: 'Location',
  components: {
    LocationSelection,
    Button,
  },
  data() {
    return {
      viewLocationList: false,
    };
  },
  computed: {
    ...mapGetters({
      getSelectedCity: 'geolocation/getSelectedCity',
    }),
  },
  methods: {
    ...mapActions('geolocation', ['setIsConfirmedCity']),
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    confirmCity() {
      this.setIsConfirmedCity(true);
      this.$emit('closeModal');
    },
    changeCity() {
      this.viewLocationList = true;
    },
    closeList() {
      this.viewLocationList = false;
    },
  },
};
