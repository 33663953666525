
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import SidebarMenu from './Menu';
import Button from '@/components/elements/Button';

export default {
  name: 'Sidebar',
  components: {
    SidebarMenu,
    Button,
  },
  props: {
    isAlwaysOnDesktop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('auth', ['hasSession']),
    ...mapState('geolocation', ['visibleLocationModal']),
    ...mapState({ isOpenedSidebar: 'isOpenedSidebar' }),
    ...mapGetters({
      menuMain: 'getMenuMain',
      menuHelp: 'getMenuHelp',
    }),
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'laptop';
    },
  },
  watch: {
    '$route.path'() {
      this.closeSidebar();
    },
  },
  beforeDestroy() {
    this.closeSidebar();
  },
  methods: {
    ...mapActions({ setIsOpenedSidebar: 'setIsOpenedSidebar' }),
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    ...mapActions('auth', ['getSocialLink']),
    async onLoginBySocial(driver) {
      try {
        const data = {
          backUrl: '/',
          driver,
          redirectUrl: `${document.location.origin}/social-login`,
        };
        const socialUrl = await this.getSocialLink(data);
        document.location.href = socialUrl;
      } catch (error) {
        return false;
      }
    },
    openAuthModal() {
      this.closeSidebar();
      this.setIsOpenedAuthModal(true);
    },
    closeSidebar() {
      this.setIsOpenedSidebar(false);
    },
  },
};
