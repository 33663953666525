
export default {
  name: 'SearchResultEmpty',
  props: {
    query: {
      type: String,
      default: '',
    },
  },
}
