import { dateFormat } from '@/plugins/formatting';

const addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; // Максимум и минимум включаются
}

export function generateDaysArray(startDate, endDate) {
  const dates = [];
  const formattedEndDate = new Date(endDate);
  let currentDate = new Date(startDate);

  while (currentDate <= formattedEndDate) {
    const formattedCurrentDate = dateFormat(
      currentDate,
      {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      },
      'fr-CA',
    );
    dates.push(formattedCurrentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
}

export function generateTimesOfDayArray() {
  const arr = [];
    let i;
  for (i = 0; i < 24; i++) {
    arr.push(i + ':00');
  }

  return arr;
}
