
import {mapGetters} from 'vuex';
import env from '@/.env.json';
import {phoneFormat} from '~/plugins/formatting';
import CustomLink from '~/components/elements/CustomLink';

export default {
  name: 'HeaderSupport',
  components: {
    CustomLink,
  },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      env,
    }
  },
  computed: {
    ...mapGetters({
      getMenuHelpAdditional: 'getMenuHelpAdditional',
    }),
    supportPhone() {
      const phone = this.getMenuHelpAdditional?.phone || ''
      return phone.replace(/[^\d+]/g, '')
    },
    supportEmail() {
      return this.getMenuHelpAdditional?.email || ''
    },
    supportTime() {
      return this.getMenuHelpAdditional?.schedule || ''
    },
    supportText() {
      return this.getMenuHelpAdditional?.text || ''
    },
    phone() {
      return this.phoneFormat(this.supportPhone);
    },
  },
  methods: {
    phoneFormat,
  },
}
