
import {mapState} from 'vuex';
import login from '~/mixins/login';
import accountChange from '~/mixins/accountChange';
import {Schema} from '~/Service/schema/Schema';

export default {
  name: 'AuthLayout',
  mixins: [login, accountChange],
  middleware: [
    'checkAuthAvailable',
  ],
  head() {
    return {
      link: [{
        rel: 'icon',
        type: 'image/x-icon',
        href: this.logo.favicon.url,
      }],
      script: [
        Schema.organization(this.logo.pc.url),
      ],
    }
  },
  computed: {
    ...mapState({logo: 'logo'}),
  },
};
