
import CustomImagePicture from '~/components/elements/CustomImage/Picture';

export default {
  name: 'CustomImage',
  components: {CustomImagePicture},
  props: {
    isBg: {
      type: Boolean,
      default: true,
    },
    image: {
      type: Object,
      default: null,
    },
    imageSrc: {
      type: String,
      default: '',
    },
    aspectRatio: {
      type: String,
      default: '100%',
    },
    alt: {
      type: String,
      default: '',
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    media: {
      type: [Array, null],
      default: null,
    },
    size: {
      type: String,
      default: 'medium',
    },
    retina: {
      type: String,
      default: 'large',
    },
  },
  computed: {
    src() {
      if (this.imageSrc) return this.imageSrc

      if (!this.image) return ''

      return this.image[this.size]
    },
    retinaSrc() {
      if (this.imageSrc) return this.imageSrc

      if (!this.image) return ''

      return this.image[this.retina]
    },
    mediaImage() {
      if (!this.media) return null

      return this.media.map(item => {

        return {
          src: item.url[this.size],
          media: item.media,
        }
      })
    },
  },
}
