const clickEvent = 'click';
const HANDLER = '_vue_clickaway_handler';

function bind(el, binding, vnode) {
    unbind(el);

  const vm = vnode.context;

  const callback = binding.value;
    if (typeof callback !== 'function') {
        return;
    }

    let initialMacrotaskEnded = false;
    setTimeout(function () {
        initialMacrotaskEnded = true;
    }, 0);

    el[HANDLER] = function (ev) {
      const path = ev.path || (ev.composedPath ? ev.composedPath() : undefined);
        if (initialMacrotaskEnded && (path ? !path.includes(el) : !el.contains(ev.target))) {
            return callback.call(vm, ev);
        }
    };

    document.documentElement.addEventListener(clickEvent, el[HANDLER], false);
}

function unbind(el) {
    document.documentElement.removeEventListener(clickEvent, el[HANDLER], false);
    delete el[HANDLER];
}

export default {
    directive: {
      bind,
      update: (el, binding) => {
        if (binding.value === binding.oldValue) return;
        bind(el, binding);
      },
      unbind,
    },
    name: 'click-outside',
};
