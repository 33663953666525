
import {mapState} from 'vuex';
import NotFound from '@/components/elements/NotFound';
import Button from '@/components/elements/Button';
import login from '~/mixins/login';
import accountChange from '~/mixins/accountChange';
import {Schema} from '~/Service/schema/Schema';
import AuthModal from '~/components/auth/Modal';
import Header from '~/layouts/components/Header';
import Footer from '~/layouts/components/Footer';
import LocationModal from '~/layouts/components/LocationModal';

export default {
  name: 'Error',
  components: {
    NotFound,
    Button,
    AuthModal,
    Header,
    Footer,
    LocationModal,
  },
  mixins: [login, accountChange],
  layout: 'errorLayout',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return {
      link: [{
        rel: 'icon',
        type: 'image/x-icon',
        href: this.logo.favicon.url,
      }],
      script: [
        Schema.organization(this.logo.pc.url),
      ],
    }
  },
  computed: {
    ...mapState('auth', ['isOpenedAuthModal']),
    ...mapState('search', ['isOpenedSearch']),
    ...mapState({logo: 'logo'}),
    ...mapState('geolocation', ['visibleLocationModal']),
    ...mapState('profile', ['modalAddCompany']),
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'laptop';
    },
  },

}
