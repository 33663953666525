
import { addSeconds, format } from 'date-fns';

export default {
  name: 'Timer',
  props: {
    seconds: {
      type: Number,
      default: 59,
    },
    format: {
      type: String,
      default: 'mm:ss',
    },
  },
  data() {
    return {
      isRunning: false,
      timer: null,
      counter: this.seconds,
    };
  },
  computed: {
    timeCurrent() {
      const seconds = addSeconds(new Date(0), this.counter);
      return format(seconds, this.format);
    },
  },
  methods: {
    start() {
      this.isRunning = true;
      this.timer = setInterval(() => {
        this.counter--;
        if (this.counter <= 0) {
          this.stop();
        }
      }, 1000);
    },
    stop() {
      this.isRunning = false;
      this.counter = 0;
      clearInterval(this.timer);
      this.$emit('timerEnd');
    },
  },
};
