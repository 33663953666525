export const validationMessages = Object.freeze({
  ERROR_REQUIRED: 'Обязательное поле',
  ERROR_NAME_REQUIRED: 'Укажите имя',
  ERROR_LASTNAME_REQUIRED: 'Укажите фамилию',
  ERROR_NAME_LAST_REQUIRED: 'Укажите фамилию и имя',
  ERROR_MIDDLENAME_REQUIRED: 'Укажите отчество',
  ERROR_NAME_INPUT: 'Превышена максимальная длина имени',
  ERROR_NAME_ALLOWED_CHAR: 'Неверно введено имя',
  ERROR_LASTNAME_ALLOWED_CHAR: 'Неверно введена фамилия',
  ERROR_MIDDLENAME_ALLOWED_CHAR: 'Неверно введено отчество',
  ERROR_PHONE_INPUT: 'Неверно введен номер',
  // удалить везде где используется
  ERROR_PHONE_EXIST: 'Такой номер уже зарегистрирован',
  ERROR_PHONE_NOT_FOUND: 'Номер телефона не найден, проверьте номер или зарегистрируйтесь',
  ERROR_CODE_INPUT: 'Неверно введен код',
  ERROR_CODE: 'Неверный код',
  ERROR_PASSWORD_INPUT: 'Как минимум 1 заглавная и строчная латинские буквы и 1 цифра',
  ERROR_PASSWORD_SAME_AS: 'Не совпадает',
  ERROR_LOGIN_AND_PASSWORD: 'Неверный логин и/или пароль',
  ERROR_NICK_NAME: 'Никнейм уже занят. Подумайте еще или используйте один из предложенных вариантов.',
  ERROR_EMAIL_REQUIRED: 'Укажите email',
  ERROR_EMAIL: 'Некорректный email',
  ERROR_EMAIL_EXIST: 'Такой email уже зарегистрирован',
  ERROR_MAX_LENGTH_LASTNAME: 'Превышена максимальная длина фамилии',
  ERROR_MAX_LENGTH_FIRSTNAME: 'Превышена максимальная длина имени',
  ERROR_MAX_LENGTH_MIDDLENAME: 'Превышена максимальная длина отчества',
  ERROR_MAX_LENGTH: 'Превышена максимальная длина',
  ERROR_REQUIRED_INDEX: 'Введите индекс',
  ERROR_REQUIRED_NAME: 'Введите имя и фамилию',
  ERROR_AGREEMENT: 'Необходимо согласие с политикой магазина',
  ERROR_INN: 'Неверный ИНН',
});

export const validationMessageMinLength = function (length) {
  return `Не менее ${length} символов`;
};
