import {discountType} from '~/plugins/enums/checkout';
import {cartPageTypes} from '~/plugins/enums/product';

export const mergeOptions = Object.freeze({
  SESSION_CART: 'sessionCart',
  USER_CART: 'userCart',
  BOTH_CARTS: 'bothCart',
});

export const mergeOptionTitles = Object.freeze({
  SESSION_CART: 'Оставить в корзине только текущие товары',
  USER_CART: 'Восстановить старую корзину',
  BOTH_CARTS: 'Объединить корзины',
});

export const discountNames = Object.freeze({
  [discountType.PRODUCT]: {
    [cartPageTypes.PRODUCTS]: 'Скидка на товар',
    [cartPageTypes.SERVICES]: 'Скидка на мастер-класс',
  },
  [discountType.DELIVERY]: 'Скидка на доставку',
  [discountType.CART]: 'Скидка на корзину',
  [discountType.CUSTOM]: 'Скидка для Вас',
  [discountType.PROMOCODE]: 'Скидка по промокоду',
})

