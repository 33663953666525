
import {mapGetters, mapMutations, mapState} from 'vuex';

import HeaderMobileMenuList from '~/layouts/components/Header/MobileMenu/List';
import HeaderMobileMenuFirst from '~/layouts/components/Header/MobileMenu/First';
import Button from '~/components/elements/Button';

export default {
  name: 'HeaderMobileMenu',
  components: {
    Button,
    HeaderMobileMenuFirst,
    HeaderMobileMenuList,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeCategories: {},
    }
  },
  computed: {
    ...mapState('auth', ['hasSession']),
    ...mapState('catalog', ['allCategories']),
    ...mapGetters('catalog', ['formattedAllCategories']),
    isShowCategories: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

  },
  methods: {
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    showCategories(evt, parent = 'root') {
      if (parent === 'root') {
        this.isShowCategories = false
        this.activeCategories = Object.assign({}, this.allCategories);
      } else {
        this.isShowCategories = true
        const parentCategory = this.formattedAllCategories[parent];
        if (parentCategory) {
          this.activeCategories = Object.assign({}, parentCategory.items);
        }
      }
    },
    onLinkClick() {
      this.$emit('linkClick')
    },
    accountHandler() {
      this.onLinkClick()
      if (!this.hasSession) {
        this.setIsOpenedAuthModal(true);
      } else {
        this.$router.push({ path: '/profile' })
      }
    },
  },
}
