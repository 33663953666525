import env from '@/.env.json';
import {priceFormat} from '~/plugins/formatting';

export const state = () => ({
  currentCurrency: env?.CURRENCY || 'RUB',
  symbols: {
    RUB: '₽',
    USD: '$',
    EUR: '€',
    KZT: '₸',
    UZS: 'Soʻm',
  },
});

export const getters = {
  getCurrentSymbol: (state) => state.symbols[state.currentCurrency],
  getCurrency: (state, getters) => (price, digits = 1, symbol = true) => {
    let currency = ` ${getters.getCurrentSymbol}`

    if (!symbol) {
      currency = ''
    }

    return `${priceFormat(price, digits)}${currency}`
  },
}

export const mutations = {
  setCurrency(state, payload) {
    state.currentCurrency = payload
  },
}
