
import { mapGetters, mapMutations, mapState} from 'vuex';
import HeaderMainActionsAccountMenu from '~/layouts/components/Header/Main/Actions/AccountMenu';

export default {
  name: 'HeaderMainActions',
  components: {
    HeaderMainActionsAccountMenu,
  },
  data() {
    return {
      isPendingLogout: false,
    }
  },
  computed: {
    ...mapState('auth', ['hasSession']),
    ...mapState({ isOpenedSearch: 'isOpenedSearch' }),

    ...mapGetters({
      getCartAllItemsCount: 'cart/getCartAllItemsCount',
      getFavoritesCount: 'favorites/getFavoritesCount',
      getAvatarPlaceholder: 'auth/getAvatarPlaceholder',
      getUserCompanies: 'auth/getUserCompanies',
      isAccountOrganization: 'auth/isAccountOrganization',
    }),
  },
  methods: {
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    openAuthModal() {
      this.setIsOpenedAuthModal(true);
    },
    onCartHandler() {
      this.$router.push({ name: 'cart' });
    },
  },
}
