export default axios => ({
  getCertificateDictionary() {
    try {
      return axios.$get('v1/certificate/dictionary')
    } catch(error) {
      throw new Error(`API Request Error (getCertificateDictionary): ${error}`);
    }
  },

  buyCertificate(payload) {
    try {
      return axios.$post('v1/certificate/buy', payload);
    } catch(error) {
      throw new Error(`API Request Error (buyCertificate): ${error}`);
    }
  },

  getCertificates() {
    try {
      return axios.$get('v1/certificate');
    } catch(error) {
      throw new Error(`API Request Error (getCertificates): ${error}`);
    }
  },
})
