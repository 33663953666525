import { productGroupTypes } from '@/plugins/enums/product';

const rangeRegx = /from-\d*-to-\d*/;
const numberRegx = /\d+/g;
const systemFilters = [
  'ids',
  'category',
  'tag',
  'brand',
  'price',
  'product_group',
  'badge',
  'search_string',
];

export function mapFilterSegments(urlSegments) {
  return urlSegments.reduce((segments, segment) => {
    const chunks = segment.split('-');
    const len = systemFilters.includes(chunks[0]) ? 1 : chunks.length - 1;
    for (let i = 1; i <= len; ++i) {
      const name = chunks.slice(0, i).join('-');
      const value = segment.replace(name + '-', '');
      if (rangeRegx.test(segment)) {
        segments[name] = segment.match(numberRegx).map((n) => +n);
      } else {
        segments[name] = segments[name] || {};
        segments[name][value] = value;
      }
    }
    return segments;
  }, {});
}

export function concatCatalogRoutePath(type, entityCode, categoryCode, segments, tagCode = '') {
  let baseRoute = '';
  switch (type) {
    case productGroupTypes.CATALOG:
    case productGroupTypes.NEW:
    case productGroupTypes.BESTSELLERS:
    case productGroupTypes.SEARCH: {
      const url = categoryCode ? `/${type}/${categoryCode}` : `/${type}`;
      baseRoute = tagCode ? `${url}/tag/${tagCode}` : url
      break;
    }
    case productGroupTypes.PROMO:
    case productGroupTypes.SETS:
    case productGroupTypes.BRANDS:
      baseRoute = categoryCode ? `/${type}/${entityCode}/${categoryCode}` : `/${type}/${entityCode}`;
      break;
    default:
      return '/';
  }

  const basePath = segments.length > 0 ? `${baseRoute}/filters` : baseRoute;

  return `${basePath}/${segments.join('/')}`
}

export function computeFilterData({
                                    pathMatch,
                                    code = null,
                                    brand = null,
                                    // eslint-disable-next-line camelcase
                                    search_string = null,
                                    // eslint-disable-next-line camelcase
                                    product_group = null,
                                    defaultFilter = {},
                                    tag = '',
                                  }) {
  const filter = {
    product_group,
    ...defaultFilter,
  };

  if (code) {
    filter.category = code
  }

  if (brand) {
    filter.brand = [brand]
  }

  // eslint-disable-next-line camelcase
  if (search_string) {
    // eslint-disable-next-line camelcase
    filter.search_string = search_string
  }

  // eslint-disable-next-line camelcase
  if (product_group) {
    // eslint-disable-next-line camelcase
    filter.product_group = product_group
  }

  if (tag) {
    filter.tag = tag
  }

  const routeSegments = pathMatch ? pathMatch.split('/').filter((s) => s !== '') : [];
  const filterSegments = mapFilterSegments(routeSegments);
  const filterNames = Object.keys(filterSegments);

  for (let i = 0; i < filterNames.length; i++) {
    const filterName = filterNames[i];
    const segment = filterSegments[filterName];

    if (Array.isArray(segment)) filter[filterName] = segment;
    else filter[filterName] = Object.keys(segment);
  }

  return { filter, routeSegments, filterSegments };
}

export function generateProductGroupUrl(type, entityCode) {
  return entityCode ? `/${type}/${entityCode}` : `/${type}`;
}

export function generateCategoryUrl(type, entityCode, categoryCode, tagCode = '') {
  switch (type) {
    case productGroupTypes.CATALOG:
    case productGroupTypes.NEW:
    case productGroupTypes.BESTSELLERS:
    case productGroupTypes.SEARCH: {
      const url = categoryCode ? `/${type}/${categoryCode}` : `/${type}`;
      return tagCode ? `${url}/tag/${tagCode}` : url
    }

    case productGroupTypes.PROMO:
    case productGroupTypes.SETS:
    case productGroupTypes.BRANDS:
      return categoryCode
        ? `${generateProductGroupUrl(type, entityCode)}/${categoryCode}`
        : generateProductGroupUrl(type, entityCode);

    default:
      return '/';
  }
}

export function generateProductUrl(categoryCode, code, refCode) {
  if (refCode) return `/${productGroupTypes.CATALOG}/${categoryCode}/${code}?refCode=${refCode}`;
  return `/${productGroupTypes.CATALOG}/${categoryCode}/${code}`;
}

export function getActiveCategories(code, item, activeItems = []) {
  if (item.code === code) return item;

  if (Array.isArray(item.items)) {
    for (let i = 0; i < item.items.length; i++) {
      const child = item.items[i];
      const found = getActiveCategories(code, child, activeItems);
      if (found) {
        activeItems.unshift(found);
        return item;
      }
    }
  }
  return false;
}

export function getAllActiveCategories(categories, code) {
  const activeCategories = [];
  let found = null;
  for (let i = 0; i < categories.length; i++) {
    const rootCategory = categories[i];
    found = getActiveCategories(code, rootCategory, activeCategories);
    if (found) {
      activeCategories.unshift(found);
      break;
    }
  }
  return activeCategories;
}

export function prepareBrand({ id, name, code, image }) {
  return {
    id,
    name,
    image,
    to: generateCategoryUrl(productGroupTypes.BRANDS, code,null),
  };
}

export function findMaxNumber(arr) {
  if (arr.length === 0) {
    return undefined;
  }

  let max = arr[0];

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      max = arr[i];
    }
  }

  return max;
}
