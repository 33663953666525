export const billingOperationType = Object.freeze({
  EARNING: 1, // начисление
  PAYMENT: 2, // оплата заказа
  REFUND: 3, // возврат
  WITHDRAWTOCARD: 4, // вывод средств на карту
  ADJUSTMENT: 5, // корректировка
  WITHDRAWTOACCOUNT: 6, // вывод средств на счёт
});


export const billingOperationTypeName = Object.freeze({
  [billingOperationType.EARNING]: 'Начисление',
  [billingOperationType.PAYMENT]: 'Оплата',
  [billingOperationType.REFUND]: 'Возврат',
  [billingOperationType.WITHDRAWTOCARD]: 'Вывод средств на карту',
  [billingOperationType.ADJUSTMENT]: 'Корректировка',
  [billingOperationType.WITHDRAWTOACCOUNT]: 'Вывод средств на счёт',
});

export const preferencesTypes = Object.freeze({
  PERSONAL: 'personal',
  PRO: 'pro',
});

export const preferencesIdByType = Object.freeze({
  personal: 1,
  pro: 2,
})

export const billingIdentificationStatus = Object.freeze({
  SUCCESS: 'success', // удачно
  FAIL: 'fail', // неудачно
  ERROR: 'error', // ошибка
});

export const referralOrderSortFields = Object.freeze({
  NAME: 'name',
  QTY: 'qty',
  SOURCE: 'source',
  ORDER_DATE: 'order_date',
  CUSTOMER_ID: 'customer_id',
  PRICE_PRODUCT: 'price_product',
  PRICE_COMMISSION: 'price_commission',
});

export const referralSource = Object.freeze({
  PROMOCODE: 1, // промокод
  LINK: 2, // реферальная ссылка
});

export const referralSourceName = Object.freeze({
  [referralSource.PROMOCODE]: 'Промокод',
  [referralSource.LINK]: 'Реферальная ссылка',
});
