
import {mapActions, mapGetters, mapState} from 'vuex';
import SearchInput from '~/layouts/components/Search/Input';
import CustomPopup from '~/components/elements/CustomPopup';
import SearchResult from '~/layouts/components/Search/Result';
import SearchResultProducts from '~/layouts/components/Search/Result/Products';
import SearchResultEmpty from '~/layouts/components/Search/Result/Empty';

export default {
  name: 'HeaderBottomSearch',
  components: {SearchResultEmpty, SearchResultProducts, SearchResult, CustomPopup, SearchInput},
  computed: {
    ...mapState('search', ['searchQuery', 'isOpenedSearch', 'isSearchPending']),
    ...mapGetters({
      getSearchSuggestions: 'search/getSearchSuggestions',
      getSearchProducts: 'search/getSearchProducts',
    }),
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    isSearchEmpty() {
      return this.searchQuery.length > 2 && !this.isSearchPending
    },
  },
  watch: {
    '$route'() {
      if (this.isOpenedSearch) {
        this.onCloseHandler()
      }
    },
  },
  methods: {
    ...mapActions('search', ['setIsOpenedSearch', 'setSearchQuery', 'clearSearchResult' ]),
  },
}
