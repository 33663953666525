import env from '@/.env.json'

export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    if (env.LIVETEX_ID && window.LiveTex?.setConversationAttributes) {
      window.LiveTex?.setConversationAttributes({
        url: to.path,
      })
    }
    next()
  })
}
