
import {mapGetters} from 'vuex';
import Support from '~/layouts/components/Header/Support';

export default {
  name: 'HeadereTopHelp',
  components: {
    Support,
  },
  data() {
    return {
      isOpenedSupportModal: false,
    }
  },
  computed: {
    ...mapGetters({
      menuHelp: 'getMenuHelp',
    }),
  },
  methods: {
    toggleSupportModal() {
      this.isOpenedSupportModal = !this.isOpenedSupportModal;
    },
    closeSupportModal() {
      this.isOpenedSupportModal = false
    },
  },
}
