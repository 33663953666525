export default async function checkReferalStatus({ store, route, error }) {
  const isReferalPartnerRoute = route.meta.find(item => item.isReferalPartnersOnlyRoute)?.isReferalPartnersOnlyRoute;

  if(!isReferalPartnerRoute) return;

  if (!checkUserReferral(store)) {
    await store.dispatch('auth/fetchUser');

    if (!checkUserReferral(store)) {
      error({ statusCode: 403, message: 'User is not referal partner' })
    }
  }
}

function checkUserReferral(store) {
  return store.state.auth?.user?.status === 5;
}
