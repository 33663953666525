import { sortDirections } from '@/plugins/enums/sort';
import {referralOrderSortFields} from '~/plugins/enums/profile';

const REFERRAL_ORDERS_PAGE_SIZE = 12

export const state = () => ({
  referralData: null,
  items: [],
  range: 0,
  activePage: 1,
  orderField: referralOrderSortFields.NAME,
  orderDirection: sortDirections.DESC,
  orderFilterField: null,
  referralOrderDetails: {},
});

export const getters = {
  pagesCount: (state) => Math.ceil(state.range / REFERRAL_ORDERS_PAGE_SIZE),
  referrals: (state) => state.referralData?.referrals || [],
  level: (state) => state.referralData?.level || null,
  levelData(state, getters) {
    /* eslint-disable */
    const { current_level, next_level } = getters.level || {
      current_level: {},
      next_level: {},
    };

    return {
      currentLevelName: current_level?.name,
      nextLevelName: next_level?.name,
    };
    /* eslint-enable */
  },
  referralArcData(state, getters) {
    /* eslint-disable */
    const { current_level, next_level, referral_count = 0 } = getters.level || {
      current_level: {},
      next_level: {},
    };

    let percent = 0

    if (current_level && next_level?.referral_count) {
      percent = Math.round((referral_count * 100) / next_level.referral_count);
    }

    return {
      current: current_level?.referral_count,
      next: next_level?.referral_count,
      value: referral_count,
      currentPercent: percent && percent > 100 ? 100 : percent,
    };
    /* eslint-enable */
  },
  sumArcData(state, getters) {
    /* eslint-disable */
    const { current_level, next_level, order_sum = 0 } = getters.level || {
      current_level: {},
      next_level: {},
    };

    let percent = 0

    if (current_level && next_level?.order_referral_sum) {
      percent = Math.round((order_sum * 100) / next_level.order_referral_sum);
    }

    return {
      current: current_level?.order_referral_sum,
      next: next_level?.order_referral_sum,
      value: order_sum,
      currentPercent: percent && percent > 100 ? 100 : percent,
    };
    /* eslint-enable */
  },
}

export const mutations = {
  SET_REFERRAL_DATA(state, payload) {
    state.referralData = payload;
  },
  SET_ORDERS(state, payload = {}) {
    state.items = payload.items || [];
    state.range = payload.range || 0;
  },
  SET_ORDERS_MORE(state, { items, range }) {
    state.items.push(...items);
    state.range = range || 0;
  },
  SET_QUERY_PARAMS(state, { page = 1, orderField, orderDirection, orderFilterField }) {
    state.activePage = Number(page);
    state.orderField = orderField;
    state.orderDirection = orderDirection;
    state.orderFilterField = orderFilterField;
  },
  SET_REFERRAL_ORDER_DETAILS(state, payload = {}) {
    state.referralOrderDetails = payload
  },
}

export const actions = {
  async getReferralStatistics({ commit }) {
    try {
      const data = await this.$api.profile.getReferralData();
      commit('SET_REFERRAL_DATA', data);
    } catch (error) {
      console.log(error)
    }
  },
  async getReferralOrders({ commit }, { page = 1, orderField, orderDirection, showMore, orderFilterField, date }) {

    try {
      const { orders: items, count: range } = await this.$api.profile.getReferralOrders(
        page,
        REFERRAL_ORDERS_PAGE_SIZE,
        orderField,
        orderDirection,
        date,
      );

      commit('SET_QUERY_PARAMS', {
        page,
        orderField,
        orderDirection,
        orderFilterField,
      });

      if (showMore) commit('SET_ORDERS_MORE', { items, range });
      else commit('SET_ORDERS', { items, range });
    } catch (error) {
      console.log(error)
    }
  },
  async getReferralData({ dispatch }, { page = 1, orderField, orderDirection, orderFilterField, date }) {
    try {
      await Promise.all([
        dispatch('getReferralStatistics'),
        dispatch('getReferralOrders', { page, orderField, orderDirection, orderFilterField, date }),
      ]);
    } catch (error) {
      console.log(error)
    }
  },
  async getReferralOrderDetails({ commit }, { id, orderField, orderDirection }) {
    try {
      const data = await this.$api.profile.getReferralOrderDetails(id, orderField, orderDirection);
      commit('SET_REFERRAL_ORDER_DETAILS', data);
    } catch (error) {
      console.log(error)
    }
  },
}
