import {dateToString, stringToDate} from '~/plugins/formatting';

/**
 * Вычисляет контрольное число.
 *
 * @param  {string}   str          Исходная строка.
 * @param  {number[]} coefficients Коэффициенты контрольной суммы.
 * @return {number}                Контрольное число.
 */
export function countCheckdigit(str, coefficients) {
  const checksum = coefficients.reduce((sum, coefficient, index) => sum + coefficient * str[index], 0);

  return (checksum % 11) % 10;
}

export function profileRequisitesFactory(requisites) {
  const {
    name = null,
    address = null,
    inn = null,
    snils = null,
    account = null,
    bank  = null,
    bik = null,
    correspondentAccount = null,
    // eslint-disable-next-line camelcase
    payment_city = null,
    // eslint-disable-next-line camelcase
    form_organization = null,
  } = requisites

  return {
    legal_info_company_name: name,
    legal_info_company_address: address,
    legal_info_inn: inn,
    legal_info_snils: snils,
    legal_info_payment_account: account,
    legal_info_bik: bik,
    legal_info_bank: bank,
    legal_info_bank_correspondent_account: correspondentAccount,
    payment_city,
    form_organization,
  }
}

export function profilePassportFactory(passport) {
  const {
    name = null,
    surname = null,
    patronymic = null,
    no = null,
    serial = null,
    issued = null,
    // eslint-disable-next-line camelcase
    issue_date = null,
    address = null,
  } = passport

  return {
    passport: {
      name,
      surname,
      patronymic,
      no,
      serial,
      issued,
      issue_date,
      address,
    },
  }
}

export function passportFactory(profile) {
  const { passport, last_name: lastName, first_name: firstName, middle_name: middleName } = profile

  return {
    firstName: passport?.name || firstName || '',
    lastName: passport?.surname || lastName || '',
    middleName: passport?.patronymic || middleName || '',
    serialNumber: `${passport?.serial || ''}${passport?.no || ''}`,
    issued: passport?.issued || '',
    issueDate: passport?.issue_date ? stringToDate(passport.issue_date) : null,
    address: passport?.address || '',
  }
}

export function requisitesFactory(profile) {
  return {
    name: profile?.legal_info_company_name || null,
    address: profile?.legal_info_company_address || null,
    inn: profile?.legal_info_inn || null,
    snils: profile?.legal_info_snils || null,
    account: profile?.legal_info_payment_account || null,
    bank: profile?.legal_info_bank || null,
    bik: profile?.legal_info_bik || null,
    correspondentAccount: profile?.legal_info_bank_correspondent_account || null,
    payment_city: profile?.payment_city || null,
    form_organization: profile?.form_organization || null,
  }
}

export function getPassportPayload(passport) {
  return {
    surname: passport.lastName,
    name: passport.firstName,
    patronymic: passport.middleName,
    serial: passport.serialNumber.substring(0, 4),
    no: passport.serialNumber.substring(4),
    issued: passport.issued,
    issue_date: dateToString(passport.issueDate),
    address: passport.address,
  }
}

export function getReferralLink(id) {
  return `${window.location.origin}?ref=${id}`
}

export function scrollToEl($el, margin = 64, headerSelector = '.header') {
  const $header = document.querySelector(headerSelector)
  const headerHeight = $header?.clientHeight || 0

  const rect = $el.getBoundingClientRect();
  const offsetTop = Math.round(rect.top + pageYOffset) - headerHeight - margin

  setTimeout(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: offsetTop,
      left: 0,
    });
  }, 0)
}

export function isElementInViewport(element) {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function isElementPartInViewport(element) {
  const rect = element.getBoundingClientRect();

  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const topVisible = rect.top >= 0 && rect.top <= windowHeight;
  const bottomVisible = rect.bottom >= 0 && rect.bottom <= windowHeight;

  return topVisible || bottomVisible;
}
