
import { mapMutations } from 'vuex';

export default {
  name: 'LayoutMenu',

  components: {},

  props: {
    mainItems: {
      type: Array,
      required: true,
    },

    secondaryItems: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapMutations('catalog', ['SET_CURRENT_CATEGORY', 'SET_CURRENT_ITEMS_FILTERS']),
  },

  methods: {
    isCurrentRoute (url) {
      const isCatalogUrl = url === '/catalog';

      return url === this.$route.path
        || isCatalogUrl && this.$route.path.includes(url) && !this.$route.params.product;
    },
  },
};
