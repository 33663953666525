
import {mapMutations} from 'vuex';
import CustomModal from '~/components/elements/CustomModal';
import CompanyAdd from '~/components/profile/company/Add';

export default {
  name: 'ModalCompanyAdd',
  components: {CompanyAdd, CustomModal},
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    ...mapMutations('profile', {
      setModalCompany: 'SET_MODAL_COMPANY',
    }),
    closeModalHandler() {
      this.setModalCompany(false)
      this.$emit('close');
    },
    onAddHandler() {
      this.closeModalHandler()
    },
  },
}
