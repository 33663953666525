
import {mapActions} from 'vuex';
import {inn, kpp} from '~/plugins/validation/requisites';
import CustomInput from '~/components/elements/CustomInput';
import CustomPopup from '~/components/elements/CustomPopup';
import CompanySearch from '~/components/profile/company/Search';
import {validationMessages} from '~/plugins/validation/messages';

export default {
  name: 'CompanyAdd',
  components: {CompanySearch, CustomPopup, CustomInput},
  props: {
    title: {
      type: Boolean,
      default: true,
    },
  },
  validations: {
    form: {
      inn: {
        inn,
      },
      kpp: {
        kpp,
      },
    },
  },
  data() {
    return {
      form: {
        inn: null,
        kpp: null,
      },
      customInnError: '',
      isOpenedInn: false,
      searchResult: {
        companies: [],
      },
      maxSuggestion: 300,
    }
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    errorInnHandler() {
      let text = '';
      const inn = this.$v.form.inn;

      if (!this.form.inn) {
        return ''
      }

      text = this.getInputError(inn,text = validationMessages.ERROR_INN)

      if (this.customInnError) text = this.customInnError

      if (text) {
        this.clearSearchResult(true)
      }

      return text;
    },
    errorKppHandler() {
      if (!this.$v.form.kpp || !this.form.inn) return ''

      let err = this.getInputError(this.$v.form.kpp, 'Неверный КПП')

      if (this.form.kpp && !this.form.inn) {
        err = 'Введите ИНН'
      }

      if (err) {
        this.clearSearchResult(true)
      }

      return err
    },
    innPlaceholder() {
      return this.mqIsMobile ? 'ИНН' : ''
    },
    kppPlaceholder() {
      return this.mqIsMobile ? 'КПП' : ''
    },
    isMultiInn() {
      return this.searchResult.companies.length >= 1
    },
  },
  methods: {
    ...mapActions({
      addUserCompany: 'profile/addUserCompany',
    }),
    async findCompany(query, kpp = '', count = 5) {
      try {
        const { suggestions } = await this.$api.geolocation.findCompanyById(query, count, kpp)
        this.existInn = suggestions.length !== 0;

        if (!this.existInn) return this.setInnError('ИНН не существует');

        const items = suggestions.map(item => this.suggestionFactory(item))
        this.showSearchResult(items)
      } catch (error) {
        console.log(error)
      }
    },
    async addCompany(company) {
      const res = await this.addUserCompany(company)
      this.$emit('add')
      if (res.exists) {
        this.setInnError('Данная компания уже заведена');
      } else if (res.other_accounts) {
        this.form.inn = '';
      }
    },
    getInputError(validate, err) {
      if (!validate.$model) return ''

      if (validate.$invalid) return err

      return ''
    },
    onInnChange(value) {
      this.form.inn = value;
      this.$v.form.$touch();
      this.setInnError('')

      if (!this.$v.form.inn.$invalid) this.findCompany(value, '', this.maxSuggestion);
    },
    onKppChange(value) {
      this.form.kpp = value;
      this.$v.form.$touch();

      if (!this.$v.form.kpp.$invalid && this.form.inn) this.findCompany(this.form.inn, value, this.maxSuggestion);
    },
    onFocusHandler() {
      if (this.searchResult.companies.length) {
        setTimeout(() => {
          this.modalStateHandler(true)
        }, 300)
      }
    },
    setInnError(err) {
      this.customInnError = err
    },
    modalStateHandler(state) {
      this.isOpenedInn = state
    },
    onClickSearch(company) {
      this.addCompany(company)
      this.clearSearchResult(true)
    },
    showSearchResult(companies) {
      this.searchResult.companies = companies
      this.modalStateHandler(true)
    },
    clearSearchResult(close = false) {
      close && this.modalStateHandler(false)

      this.searchResult = {
        companies: [],
      }
    },
    suggestionFactory(suggestion) {
      return {
        name: suggestion?.value || '',
        inn: suggestion?.data?.inn || '',
        kpp: suggestion?.data?.kpp || '',
        address: suggestion?.data?.address?.unrestricted_value || '',
      }
    },
  },
}
