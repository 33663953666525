
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import _debounce from 'lodash/debounce';
import { suggestionTypes } from '@/plugins/enums/suggestions';
import env from '@/.env.json';
import LoaderDots from '~/components/elements/LoaderDots';
import CustomSelect from '~/components/elements/CustomSelect';

export default {
  name: 'LocationSelection',
  components: {CustomSelect, LoaderDots},
  props: {
    title: {
      type: String,
      default: '',
    },
    defaultCountry: {
      type: String,
      default: env.COUNTRY,
    },
  },
  data() {
    return {
      focus: false,
      searchString: null,
      suggestions: [],
      isAddressPending: false,
      searchPerformed: false,
    };
  },
  computed: {
    ...mapGetters({
      getAddresses: 'checkout/getAddresses',
      getCountriesList: 'geolocation/getCountriesList',
      getSelectedCountry: 'geolocation/getSelectedCountry',
    }),
    ...mapState({
      checkoutData: 'checkout/checkoutData',
    }),
    ...mapState('geolocation', [
      'visibleLocationModal',
      'selectedCountry',
    ]),
    currentCountry: {
      get() {
        return this.selectedCountry
      },
      set(value) {
        this.setSelectedCountry(value)
      },
    },
  },
  watch: {
    searchString(value) {
      if (value !== null) {
        this.searchPerformed = true;
      }
      this.debounce_onCityInputChange(value);
    },
    visibleLocationModal(value) {
      value && !this.suggestions.length && this.onCityInputChange()
    },
    selectedCountry() {
      this.onCityInputChange()
    },
  },
  beforeMount() {
    // this.setDefaultCountry()
    this.debounce_onCityInputChange = _debounce(this.onCityInputChange, 300);
  },
  methods: {
    ...mapActions({
      setSelectedCity: 'geolocation/setSelectedCity',
      findAddress: 'geolocation/findAddress',
      setAddress: 'checkout/setAddress',
      setIsConfirmedCity: 'geolocation/setIsConfirmedCity',
      selectCity: 'geolocation/selectCity',
    }),
    ...mapMutations({
      setSelectedCountry: 'geolocation/SET_SELECTED_COUNTRY',
    }),
    async onCityInputChange(query = '') {
      try {
        const suggestQuery = query || suggestionTypes.CITY;
        const { suggestions } = await this.findAddress({
          type: suggestionTypes.CITY,
          bound: suggestionTypes.CITY,
          query: suggestQuery,
          count: 20,
          locations: [{ country_iso_code: this.getSelectedCountry.value }],
        });

        const cities = []

        suggestions.every(item => {
          if (cities.findIndex(i => i.data.city === item.data.city) === -1) {
            cities.push(item)
          }

          return cities.length < 9
        })


        // this.suggestions = suggestions;
        this.suggestions = cities;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async selectCityHandler(suggestion) {
      if (this.isAddressPending) return;
      this.isAddressPending = true;
      await this.selectCity({
        suggestion,
        locations: [ { country_iso_code: this.getSelectedCountry.value } ],
      })

      this.isAddressPending = false;
      this.close();
    },
    close() {
      this.$emit('close');
    },
    setDefaultCountry() {
      const code = this.defaultCountry
      const item = this.getCountriesList.find(item => item.value.toLowerCase() === code.toLowerCase())

      if (item) {
        this.setSelectedCountry(item)
      }
    },
  },
};
