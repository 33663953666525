
import {mapActions, mapMutations, mapState} from 'vuex';
import env from '@/.env.json';
import { validationMessages } from '@/plugins/validation/messages';
import { authModalPages } from '@/plugins/enums/auth';
import AuthModalFooter from '@/components/auth/Modal/Footer';
import Button from '@/components/elements/Button';
import InputTel from '~/components/elements/InputTel';

export default {
  name: 'AuthModalSignUpPhone',
  components: {
    InputTel,
    AuthModalFooter,
    Button,
  },
  props:{
    timer: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      authModalPages,
      phoneMask: '',
      isDisabledGetCodeBtn: false,

      validatedPhone: {
        isValid: false,
        international: '',
        number: '',
      },
      isSocialAuth: env.SOCIAL_AUTH,
    }
  },
  computed: {
    ...mapState('auth', ['isOpenedAuthModal']),
    phoneError() {
      let text = '';

      if (!this.isStartPhone) {
        if (!this.validatedPhone.isValid) text = validationMessages.ERROR_PHONE_INPUT;
      }

      return text;
    },
    isStartPhone() {
      return !this.phoneMask;
    },
    isDisabledNextButton() {
      return this.isDisabledGetCodeBtn || !!this.phoneError.length || this.timer > 0;
    },
  },
  methods: {
    ...mapMutations('auth', {
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
      setAcceptedPhone: 'SET_ACCEPTED_PHONE',
      setNewUser: 'SET_NEW_USER',
    }),
    ...mapActions('auth', ['loginByPhoneSendSms']),
    onValidateHandler(phone) {
      this.validatedPhone = phone
    },
    toStart() {
      this.setCurrentAuthModalPage(authModalPages.INDEX);
    },
    onSubmitPhone() {
      if (this.validatedPhone.isValid) {
        this.goToSendingSms();
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
    startTimer() {
      this.$emit('startTimer');
    },
    async goToSendingSms() {
      const data = { phone: this.validatedPhone.number };
      
      this.isDisabledGetCodeBtn = true;
      this.startTimer();
      this.setAcceptedPhone(this.validatedPhone.international);
      this.setCurrentAuthModalPage(authModalPages.SIGN_UP_CODE);

      try {
        const { isNew } = await this.loginByPhoneSendSms(data);
        this.setNewUser(isNew);
      } catch (error) {
        console.log(error);
      } finally {
        this.isDisabledGetCodeBtn = false;
      }
    },
  },
};
