
import HeaderBottomMenu from '~/layouts/components/Header/Bottom/Menu.vue';
import HeaderBottomCatalog from '~/layouts/components/Header/Bottom/Catalog.vue';

export default {
  name: 'HeaderBottom',
  components: {
    HeaderBottomCatalog,
    HeaderBottomMenu,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isScrollOffset: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isShowCategories: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    onLinkClick() {
      this.$emit('linkClick')
    },
  },
}
