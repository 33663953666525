
import CustomLink from '~/components/elements/CustomLink';

export default {
  name: 'FooterNav',
  components: {
    CustomLink,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpenedMenu: false,
    };
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
  },
  watch: {
    mqIsMobile(value) {
      if (!value) {
        this.isOpenedMenu = true;
        this.$refs.menu.style.height = 'auto';
      } else {
        this.isOpenedMenu = false;
      }
    },
  },
  mounted() {
    this.isOpenedMenu = !this.mqIsMobile;
  },
  methods: {
    toggleMenu() {
      this.isOpenedMenu = !this.isOpenedMenu
    },
    beforeEnter(el) {
      el.style.height = 0;
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    leave(el, done) {
      this.$nextTick(() => {
        el.style.height = 0;
        setTimeout(done, 300);
      });
    },
  },
};
