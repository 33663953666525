
export default {
    name: 'MenuToggle',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      hideTextOnStart: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      toggleMenuOpened() {
        this.$emit('input', !this.value);
      },
    },
}
