
import { mapState } from 'vuex';

import Header from './components/Header';
import Footer from './components/Footer';
import SidebarWrapper from './components/Sidebar/Wrapper';
import LocationModal from '@/layouts/components/LocationModal';
import login from '~/mixins/login';
import CookiesAccept from '~/components/elements/CookiesAccept.vue';
import ModalCompanyAdd from '~/components/elements/modals/Company/Add';
import AuthModal from '~/components/auth/Modal';
import accountChange from '~/mixins/accountChange';
import {Schema} from '~/Service/schema/Schema';

export default {
  name: 'HiddenSidebarLayout',
  components: {CookiesAccept, Header, Footer, SidebarWrapper, LocationModal, ModalCompanyAdd, AuthModal },
  mixins: [login, accountChange],
  head() {
    return {
      link: [{
        rel: 'icon',
        type: 'image/x-icon',
        href: this.logo.favicon.url,
      }],
      script: [
        Schema.organization(this.logo.pc.url),
      ],
    }
  },
  computed: {
    ...mapState({ isOpenedSidebar: 'isOpenedSidebar' }),
    ...mapState('geolocation', ['visibleLocationModal']),
    ...mapState('profile', ['modalAddCompany']),
    ...mapState('auth', ['isOpenedAuthModal']),
    ...mapState({logo: 'logo'}),
  },
};
