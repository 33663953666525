
export default {
  name: 'HeaderMobileMenuList',
  props: {
    activeCategories: {
      type: [Object, Array],
      required: true,
    },
  },
  methods: {
    onLinkClick() {
      this.$emit('linkClick')
    },
    showCategories(event, parent) {
      this.$emit('showCategories', event, parent)
    },
  },
}
