
export default {
  name: 'Logo',
  props: {
    logo: {
      type: String,
      required: true,
    },
    mobile: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    wrapperClass: {
      type: String,
      default: 'logo-wrapper',
    },
  },
  computed: {
    logoBg() {
      return {
        '--_logo-bg-mobile': `url(${this.mobile ? this.mobile : this.logo})`,
        '--_logo-bg-pc': `url(${this.logo})`,
      }
    },
  },
}
