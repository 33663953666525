import {getReceiverPayload, getReceiversData} from '~/plugins/helpers/receivers';

export const actions = {
  async getContacts() {
    const { receivers } = await this.$api.profile.getReceivers()
    return getReceiversData(receivers)
  },
  async createContact(ctx, { recipient, fileId }) {
    try {
      const { receiver } = await this.$api.profile.createReceiver({
        ...getReceiverPayload(recipient, fileId),
      })

      return receiver
    } catch (e) {
      console.log(e)
    }
  },
  async updateContact(ctx, { id, recipient, fileId }) {
    try {
      const { receiver } = await this.$api.profile.updateReceiver(id, {
        ...getReceiverPayload(recipient, fileId),
      })

      return receiver
    } catch (e) {
      console.log(e)
    }
  },
  async uploadFiles(ctx, files) {
    if (!files?.length) return null

    const formData = new FormData()

    formData.append('file', files[0].file)

    const file = await this.$api.profile.receiverUpload(formData)

    return file.id || null
  },
  async removeContact(ctx, id) {
    await this.$api.profile.removeReceiver(id)
  },
  async createOrUpdateContact({ dispatch }, { id, recipient }) {
    let updated = null

    const fileId = await dispatch('uploadFiles', recipient.files)

    if (!id) {
      updated = await dispatch('createContact', { recipient, fileId })
    } else {
      updated = await dispatch('updateContact', { id, recipient, fileId })
    }

    return updated
  },
}
