import {GTMEvent} from '~/Service/GTM/GTMEvent';

import {
  // RetailRocket,
} from '~/Service/GTM/clients';

export const state = () => ({

})

export const getters = {

}

export const actions = {
  emitGTMEventHome({getters}) {
    const gtmEvent = new GTMEvent([])

    GTMPush.call(this, 'home', gtmEvent.data)
  },
  emitGTMEventCatalogListing({getters}, {products, category, catalog}) {
  },
  emitGTMEventProductView({getters}, {product, category}) {
  },
  emitGTMEventCheckout({getters}) {
  },
  emitGTMEventSuccess({getters}, {orderId, totalAmount, coupon, deliveryAmount, cartProducts}) {
  },
  emitGTMEventOtherListing({getters}) {
  },
  emitGTMEventCartAdd(ctx, product) {
  },
  emitGTMEventCartRemove(ctx, {product, qty}) {
  },
  emitGTMEventAddWishlist(ctx, productId) {
  },
  emitGTMEventDelWishlist(ctx, productId) {
  },
  emitGTMEventLogin(ctx, customerInfo) {
  },
  emitGTMEventSubscribe(ctx, email) {
  },
  emitGTMEventEmailChange(ctx, email) {
  },
}

export const mutations = {

}

function GTMReset() {
  this.reset()
}

function GTMDelayedPush(data, ms) {
  return new Promise(resolve => {
    if (!data) {
      resolve()
      return
    }

    const keys = Object.keys(data)

    if (!keys.length) {
      resolve()
      return
    }

    let pos = 0

    const interval = setInterval(() => {
      if (pos >= keys.length) {
        clearInterval(interval)
        resolve();
        return
      }

      const key = keys[pos++]
      this.$gtm.push({ [key]: data[key] })
    }, ms)
  });
}

async function GTMPush(event, data = null) {
  this.$gtm.push(GTMReset)
  await GTMDelayedPush.call(this, data, 5)
  this.$gtm.push({ event })
}
