import {mapActions, mapMutations, mapState} from 'vuex';
import appHeight from '~/mixins/appHeight';

export default {
  mixins: [appHeight],
  computed: {
    ...mapState('auth', ['hasSession', 'firstLoad', 'newReferrerCode']),
  },
  methods: {
    ...mapMutations('auth', {
      setFirstLoad: 'SET_FIRST_LOAD',
    }),
    ...mapActions({
      checkSession: 'auth/checkSession',
      setReferralSession: 'auth/setReferralSession',
      fetchUser: 'auth/fetchUser',
      getFavoritesAll: 'favorites/getFavoritesAll',
    }),
  },
  async mounted() {
    if (!this.hasSession) {
      const isLogin = await this.checkSession({ force: true, favorites: false })

      if (isLogin && this.firstLoad) {
        this.setFirstLoad(false)

        await this.fetchUser()
        await this.getFavoritesAll()
      }
    }

    this.newReferrerCode && this.setReferralSession(this.newReferrerCode)
  },
}
