
export default {
  name: 'CustomSidebar',
  props: {
    sidebarWidth: {
      type: Number,
      default: 248,
    },
    sidebarPos: {
      type: String,
      default: 'left',
    },
  },
  computed: {
    sidebarStyle() {
      return {
        '--sidebar-width': `${this.sidebarWidth}px`,
      }
    },
  },
}
