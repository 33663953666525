export const sortOptions = Object.freeze({
  POPULARITY: 'popularity', // default
  PRICE: 'price',
  NEW: 'new',
  DISCOUNT: 'discount',
  RELEVANCE: 'relevance', // default for search
});

export const sortDirections = Object.freeze({
  DESC: 'desc',
  ASC: 'asc',
});

export const sortOptionsList = [
  {
    id: 1,
    title: 'Популярные',
    value: sortOptions.POPULARITY,
    direction: sortDirections.DESC,
  },
  {
    id: 2,
    title: 'Сначала дороже',
    value: sortOptions.PRICE,
    direction: sortDirections.DESC,
  },
  {
    id: 3,
    title: 'Сначала дешевле',
    value: sortOptions.PRICE,
    direction: sortDirections.ASC,
  },
  {
    id: 4,
    title: 'Новинки',
    value: sortOptions.NEW,
    direction: sortDirections.DESC,
  },
  {
    id: 5,
    title: 'По размеру скидки',
    value: sortOptions.DISCOUNT,
    direction: sortDirections.DESC,
  },
  {
    id: 6,
    hideOn: ['catalog'],
    title: 'По релевантности',
    value: sortOptions.RELEVANCE,
    direction: sortDirections.DESC,
  },
];

export const sortOptionsListFavorites = [
  {
    id: 1,
    title: 'Сначала дороже',
    value: sortOptions.PRICE,
    direction: sortDirections.DESC,
  },
  {
    id: 2,
    title: 'Сначала дешевле',
    value: sortOptions.PRICE,
    direction: sortDirections.ASC,
  },
];
