
import {mapActions, mapState} from 'vuex';

export default {
  name: 'SearchInput',
  computed: {
    ...mapState('search', [ 'searchQuery']),
    searchString: {
      get() {
        return this.searchQuery
      },
      set(newValue) {
        this.setSearchQuery(newValue)
      },
    },
  },
  methods: {
    ...mapActions('search', ['onInputFocus', 'setSearchQuery', 'clearSearchResult']),
    clearSearch() {
      this.clearSearchResult()
    },
    onFocusHandler() {
      this.onInputFocus()
      this.$emit('onFocus')
    },
    onKeyEnter() {
      this.$router.push({
        name: 'search',
        query: { search_string: this.searchString },
      });
      this.$emit('onEnter')
    },
  },
}
