
export default {
  name: 'SearchResultSuggestions',
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
    itemClass: {
      type: String,
      default: '',
    },
    itemIcon: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    isString(item) {
      return typeof item === 'string'
    },
    onClickSuggestion(item) {
      this.$emit('suggestion', item)
    },
  },
}
