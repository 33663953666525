
export default {
  name: 'CustomModal',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    empty: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    contentSize: {
      type: Boolean,
      default: false,
    },
    closeIcon: {
      type: String,
      default: 'close',
    },
    closeTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      scrollLock: false,
    };
  },
  watch: {
    visible(value) {
      this.scrollLock = value;
    },
  },
  mounted() {
    this.scrollLock = this.visible;
  },
  beforeDestroy() {
    this.scrollLock = false;
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
