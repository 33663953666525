
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import CustomModal from '~/components/elements/CustomModal';
import LoaderDots from '~/components/elements/LoaderDots';
import {phraseDeclension} from '~/plugins/formatting';
import CustomRate from '~/components/elements/CustomRate';
import Button from '~/components/elements/Button';
import QuickLookGallery from '~/components/catalog/quickLook/Gallery';

export default {
  name: 'QuickLookModal',
  components: {
    QuickLookGallery,
    Button,
    CustomRate,
    LoaderDots,
    CustomModal,
  },
  data() {
    return {
      productImages: [],
      wordVariantsBonus: ['бонус', 'бонусов', 'бонуса'],
      wordVariantsParameters: ['вариант', 'вариантов', 'варианта'],
    }
  },
  computed: {
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
    ...mapState('quickLook', ['modalState', 'isPending', 'code', 'isHiddenPending']),
    ...mapGetters('cart', ['productInCart', 'getProductCount', 'getProductCountWithBundle']),
    ...mapState('product', ['product']),
    ...mapState('auth', ['hasSession']),
    ...mapGetters('product', [
      'getPrice',
      'getOldPrice',
      'getOptionsCharacteristics',
      'getNextCombination',
      'getCategoryLink',
    ]),
    ...mapGetters('favorites', ['productInFavorites']),
    mqIsMobile() {
      return this.$mq === 'mobileXs';
    },
    isFew() {
      return this.product.stock.qty <= 5 && this.product.stock.qty > 1;
    },
    isLast() {
      return this.product.stock.qty === 1;
    },
    isFavorites() {
      return this.productInFavorites(this.product.productId);
    },
    textFavorite() {
      return this.isFavorites ? 'В избранном' : 'В избранное';
    },
    isMax() {
      return (
        this.getProductCount(this.product.id) >= this.product.stock.qty ||
        this.getProductCountWithBundle(this.product.id) >= this.product.stock.qty
      );
    },
    productGallery() {
      return this.productImages || []
    },
  },
  watch: {
    product() {
      this.setProductImages()
    },
  },
  methods: {
    phraseDeclension,
    ...mapMutations({
      setIsOpenedAuthModal: 'auth/SET_IS_OPENED_AUTH_MODAL',
    }),
    ...mapActions({
      hideQuickProduct: 'quickLook/hideQuickProduct',
      showQuickProduct: 'quickLook/showQuickProduct',
      addToCartProduct: 'cart/addToCartProduct',
      addFavoritesItem: 'favorites/addFavoritesItem',
      deleteFavoritesItem: 'favorites/deleteFavoritesItem',
      ymetricaEmitProductAddToCart: 'YMetrica/emitProductAddToCart',
    }),
    closeModalHandler() {
      this.hideQuickProduct()
    },
    innerStyle(color) {
      return {
        backgroundColor: `#${color}`,
      };
    },
    onSelectOption(charCode, optValue) {
      const { code } = this.getNextCombination(charCode, optValue);

      this.showQuickProduct({ code, category: this.getCategoryLink, hidden: true })
    },
    async onAddCartButtonClick() {
      try {
        // eslint-disable-next-line no-undef
        rrApi.addToBasket(this.product.id);
      } finally {
        await this.addToCartProduct(this.product);
        this.ymetricaEmitProductAddToCart({product: this.product, quantity: 1});
        this.closeModalHandler()
      }
    },
    toggleToFavoritesItem() {
      if (!this.hasSession) {
        this.setIsOpenedAuthModal(true);
        return
      }

      if (this.isFavorites) {
        this.deleteFavoritesItem(this.product.productId);
      } else {
        this.addFavoritesItem(this.product.productId);
      }
    },
    setProductImages() {
      const images = {};
      const media = this.product.media;

      if (Array.isArray(media) && media.length > 0) {
        images.media = media.map((image) => {
          return {
            url: image.url,
          };
        });
      } else {
        images.media = [];
      }

      this.productImages = images.media;
    },

  },
}
