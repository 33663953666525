import * as utils from './utils'

const base = { type: 'application/ld+json', json: '' }

export class Schema {
  static organization(logo) {
    return {
      ...base,
      json: utils.getOrganization(logo),
    }
  }
}


