
import env from '@/.env.json';

export default {
  name: 'Support',
  data() {
    return {
      env,
    }
  },
  computed: {
    social() {
      const social = []

      if (this.env.FOOTER_YOUTUBE) {
        social.push({
          id: 1,
          icon: 'youtube',
          title: 'Связаться с нами в youtube',
          href: this.env.FOOTER_YOUTUBE,
        })
      }

      if (this.env.FOOTER_TELEGRAM) {
        social.push({
          id: 2,
          icon: 'telegram',
          title: 'Связаться с нами в telegram',
          href: this.env.FOOTER_TELEGRAM,
        })
      }

      if (this.env.FOOTER_INSTAGRAM) {
        social.push({
          id: 3,
          icon: 'instagram',
          title: 'Связаться с нами в Instagram',
          href: this.env.FOOTER_INSTAGRAM,
        })
      }

      if (this.env.FOOTER_VK) {
        social.push({
          id: 3,
          icon: 'vkontakte',
          title: 'Связаться с нами в VK',
          href: this.env.FOOTER_VK,
        })
      }

      return social
    },
  },
};
