import qs from 'qs';

export default (axios) => ({
  getServiceDetail(code = null) {
    try {
      return axios.$get('/v1/public-events/detail', {
        params: {
          code,
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getAllServices): ${error}`);
    }
  },
  getServiceItems({ page, orderField, orderDirection, filter } = {}) {
    try {
      return axios.$get('/v1/public-events', {
        params: {
          filter,
          page,
          orderField,
          orderDirection,
        },
        paramsSerializer(params) {
          return qs.stringify(params, {
            encode: false,
          });
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getAllServices): ${error}`);
    }
  },
  getAllServiceFilters(excludedFilters) {
    try {
      return axios.$get('/v1/public-events/filters', {
        params: {
          excludedFilters,
        },
      });
    } catch (error) {
      throw new Error(`API Request Error (getAllServiceFilters): ${error}`);
    }
  },
});
