import qs from 'qs';

export default axios => ({
  async search(value) {
    if (value) {
      try {
        const response = await axios
          .$get('/v1/search', {
            params: {
              query: value,
            },
            paramsSerializer(params) {
              return qs.stringify(params, {
                encode: false,
              });
            },
          })
        return response;
      } catch (error) {
        throw new Error(`API Request Error (search): ${error}`);
      }
    }

    return {};
  },
});
